@import "@styles/variables";

.btn {
  border: none;
  cursor: pointer;
  box-shadow: none;

  svg {
    vertical-align: middle;
  }
}

.btn-primary {
  &:not(:disabled):hover {
    color: $primary-color !important;

    svg {
      fill: $primary-color;

      path {
        fill: $primary-color;
      }
    }
  }

  svg {
    path {
      fill: $white-color;
    }
  }
}

.btn-primary-inverse {
  background-color: $white-color;
  color: $primary-color;

  &:not(:disabled):hover {
    background-color: $primary-color;
    color: $white-color;

    svg {
      fill: $white-color;

      path {
        fill: $white-color;
      }
    }
  }

  svg {
    fill: $primary-color;

    path {
      fill: $primary-color;
    }
  }
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid $oxford-blue;
  color: $oxford-blue;

  &:not(:disabled):hover {
    color: $white-color !important;
    background-color: $oxford-blue !important;
    border: 1px solid $white-color;
  }
}

.btn-disabled {
  svg {
    fill: $neutral-50;

    path {
      fill: $neutral-50;
    }
  }
}

.btn-link {
  background-color: transparent !important;
  border-bottom: 2px solid $primary-color;
  padding: 0 0 2px;
  color: $oxford-blue;
  border-radius: 0px;

  &:not(:disabled):hover {
    border-bottom-color: $white-color;
    color: $oxford-blue;
  }
}
