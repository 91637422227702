@import "@styles/variables";

.arrowBtn {
  background: $primary-light !important;
  color: $black-color !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowBtn::before {
  content: "" !important;
}
.arrowContainer {
  background-color: $primary-light;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $white-color;
    border: 1px solid $oxford-blue;
  }
  @media (hover: none) {
    &:hover {
      background-color: $primary-light;
      border: inherit;
    }
  }
}
