@import "@styles/variables";
.viewedItemsWrapper {
  .btn {
    width: 60px;
    padding: 4px !important;
    span {
      display: block;
    }
    &:hover {
      svg {
        fill: $white-color;
      }
    }
  }
}
.cardsWrapper {
  width: 100%;
  margin: 0 !important;
  row-gap: 16px;
  .productCardWrapper {
    @media (min-width: $screen-width-lg) {
      &:nth-child(odd) {
        padding-right: 8px;
      }
      &:nth-child(even) {
        padding-left: 8px;
      }
    }
    @media (min-width: $screen-width-xs) and (max-width: $screen-width-md) {
      &:nth-child(odd) {
        padding-right: 8px;
      }
      &:nth-child(even) {
        padding-left: 8px;
      }
    }
  }
}
