@import "@styles/variables";

.pagination {
  margin-top: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4rem;
  ul {
    display: flex;
  }
  ul li {
    width: 38px !important;
    height: 38px !important;
    border-radius: 100% !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    justify-content: center !important;
    align-items: center !important;
    @media (max-width: $screen-width-sm) {
      width: 30px !important;
      height: 30px !important;
    }

    a {
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
  button {
    border: none !important;
  }
}
