@import "@styles/variables";

html,
body {
  padding: 0;
  margin: 0;
  font-family: $primary-font-family !important;
  color: $oxford-blue;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: normal;
  }

  body {
    color: $oxford-blue;
    background: white;
  }
}

.slick-slider {
  .slick-slide {
    margin-inline-end: 15px;
  }

  .slick-list {
    padding: 0px !important;
    padding-top: 20px !important;
    max-height: 525px;
  }

  .slick-track {
    display: flex;
    margin-bottom: 40px;
  }

  .slick-arrow {
    background: rgba($white-color, 0.85) !important;
    display: block;
    top: -50px !important;
    position: absolute;
    z-index: 0;
    width: 40px !important;
    height: 40px !important;
    @media (max-width: $screen-width-sm) {
      top: -35px !important;
    }
  }

  .slick-next {
    right: 10px !important;
    @media (max-width: $screen-width-sm) {
      right: 0px !important;
    }
  }
  .slick-prev {
    left: calc(100% - 100px) !important;
  }

  @media (max-width:$screen-width-sm){
    .slick-prev {
      left: calc(100% - 90px) !important;
    }
  }

  .slick-dots-bottom {
    bottom: -20px !important;
    z-index: 0;
  }

  .slick-dots li {
    width: 10px !important;

    button {
      background-color: #ecdfd5 !important;
      width: 10px !important;
      height: 10px !important;
      border-radius: 50% !important;
      opacity: 1 !important;
      &::before {
        opacity: 0;
        content: "";
      }
    }
  }

  .slick-dots li.slick-active button {
    background-color: $primary-color !important;
  }
}

.ant-form-item-explain-error {
    padding-left: 12px;
    font-size: 10px;
  }
ul {
  li.ant-pagination-options {
    display: none !important;
  }
}
.ant-pagination-item-active {
  background-color: $primary-color !important;
  a {
    color: $white-color !important;
  }
}

.font-italic {
  font-style: italic;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.w-100 {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}

.h-auto {
  height: auto;
}

.text-capitalize {
  text-transform: capitalize;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.float-right {
  float: right;
}

.overflow-hidden {
  overflow: hidden;
}
.custom-tooltip{
  z-index: 1;
}
.border-0 {
  border: 0;
}

@media (min-width: $screen-width-xl) {
  .ant-drawer-content-wrapper {
    width: 550px !important;
  }
}

@media screen and (max-width: 768px) {
  .ant-collapse .ant-collapse-item {
    .ant-collapse-header {
      padding: 0px !important;
      align-items: center !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0px !important;
      margin-top: 1rem !important;
    }
  }
  .mobile-d-none {
    display: none;
  }
}

.collapse-opened {
  overflow: hidden !important;
  height: 100vh;
}
#pac-container-parent, #pac-container-parent-destination {
  .pac-container {
    top: 0 !important;
    left: 0 !important;
  }
}

._hj_feedback_container {
  position: relative;
  z-index: 90;
}

#hubspot-messages-iframe-container {
  z-index: 900 !important;
}

.ant-tooltip {
  z-index: 1000;
}

.ant-modal-root {
  .ant-modal-header {
    padding-right: 16px;
  }
}

.position-relative {
  position: relative;
}
