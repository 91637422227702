.LoadingWithOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff8;
  z-index: 1000;
}
