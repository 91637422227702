@import "@styles/variables";

.paymentMethods {
  height: 100%;
  :global {
    .ant-tabs {
      .ant-tabs-tab {
        padding: 16px 24px;
        margin-bottom: 16px;
        border-radius: 12px !important;
      }
      .ant-tabs-tab-active {
        background: rgb(213, 230, 249, 0.2);
        margin-bottom: 0;
        border-radius: 12px 12px 0 0 !important;
        border-bottom: 0;
      }
      .ant-tabs-nav-list {
        gap: 16px;
      }
      .ant-tabs-nav-operations {
        display: none;
      }
      .ant-tabs-nav-wrap {
        white-space: break-spaces;
      }
    }
    .ant-tabs-nav {
      margin: 0;
    }
  }
  .creditLineLink{
    &:hover{
      color: inherit;
    }
    &:focus{
      color: $oxford-blue;
    }
  }
  .tabsTitle {
    display: flex;
    column-gap: 16px;
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: $screen-width-md) {
        display: none;
      }
    }
    .titles {
      font-size: 16px;
      line-height: 600px;
      line-height: 19px;
    }
    .content,.palletActive {
      font-size: 12px;
      line-height: 19px;
      opacity: 70%;
      max-width: 210px;
      white-space: break-spaces;
    }
    .palletActive{
    color: green;
    }
    .achimg {
      width: 46px;
      height: auto;
      margin: auto;
    }
  }
  .title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    padding-bottom: 20px;
  }
}
.Modal {
  :global {
    .ant-modal-header {
      padding-top: 20px;
      max-width: 420px;
      text-align: center;
      padding-bottom: 20px;
    }
    .ant-modal-footer {
      button {
        width: 148px;
        height: 56px;
      }
    }
  }
}

.cardsLoadingWrapper {
  opacity: 0.4;
  pointer-events: none;
}

.loader {
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}