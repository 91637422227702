@import "@styles/variables";

.default {
  transform: rotate(0);
}
.left {
  transform: rotate(90deg);
}
.right {
  transform: rotate(-90deg);
}

.top {
  transform: rotate(180deg);
}
.bottom {
  transform: rotate(-180deg);
}
