@import "@styles/variables";
.haggleModal {
  text-align: center !important;
  :global {
    .ant-form-item {
      width: 100%;
    }
    .ant-modal-content {
      border-radius: 0;
      @media (min-width: $screen-width-md) {
        padding: 66px 65px 45px;
      }
      .ant-select-selection-placeholder, .ant-select-selection-item{
        font-size: 12px;
        text-align: start;
      }
    }
  }
}
.haggleForm {
  input {
    @media (min-width: $screen-width-md) {
      font-size: 12px;
    }
    border-radius: 0;
    padding: 16px 12px !important;
  }
  :global {
    .ant-select-selector {
      align-items: center;
      border-radius: 0;
      height: 48px !important;
    }
    .ant-select-selection-item {
      align-self: center;
    }
  }
}
.submitButton {
  text-align: center;
  button {
    width: 300px;
  }
}
