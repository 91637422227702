@import "@styles/variables";

.left-container {
  background: $secondary-yellow
    url("https://22338452.fs1.hubspotusercontent-na1.net/hubfs/22338452/images_storefront/registerForm.png.png?auto=webp&format=png")
    center bottom no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  .imageContainer {
    padding-left: 80px;
    @media (max-width: $screen-width-lg) {
      padding-left: 40px;
    }
  }
}

.yourSupply {
  margin-top: 64px;
  @media (max-width: $screen-width-lg) {
    margin-top: 32px;
    font-size: 50px;
  }
}
.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageContainer {
  margin-bottom: 40px;
  @media (max-width: $screen-width-md) {
    margin-bottom: 28px;
  }
}
.welcomeToEPallet {
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 16px;
  @media (max-width: $screen-width-md) {
    font-size: 24px;
    margin-bottom: 12px;
  }
}
.emailSent {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 32px;
  @media (max-width: $screen-width-md) {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 24px;
  }
}
.resendEmail {
  width: 159px;
  height: 49px;
  font-weight: 700;
  font-size: 16px;
}
.height-50 {
  height: 48px;
}
.border-radius {
  border-radius: 0;
}
.vendor-button:not(:disabled):hover {
  border-bottom-color: $primary-color;
}
.sign-up-divider {
  color: #404b60;
  height: 1px;
}
.text-align {
  text-align: center;
}
.congratulationsSection {
  align-items: center;
  text-align: center;
  flex-flow: column;
}
.google-icon-container {
  cursor: pointer;
  margin: auto;
  height: 48px;
  width: 200px;
  svg {
    border: 1px solid $neutral-30;
    border-radius: 50px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.signup-types {
  display: flex;
  overflow-x: auto;
}
.outer-container {
  :global {
    .ant-input-suffix {
      div {
        height: 24px;
      }
    }
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
    }
  }
}
.heigh-23 {
  height: 23px;
}

@media (max-width: $screen-width-md) {
  .signup-title {
    font-size: $fs-h4;
    color: $black-color;
  }
  .form-container {
    padding: 0;
  }
  .news-letters-text {
    font-size: $fs-sm;
    font-weight: $medium-font-weight;
  }
}
.newsLetters-container {
  display: flex;
}
