@import "@styles/variables";

.card {
  border: 2px solid $neutral-30;
  border-radius: 12px;
  .productLink{
    cursor: pointer;
    &:hover{
      color: inherit;
    }
  }
  .productName {
    word-break: break-word;
  }
  &:not(:first-child) {
    margin-top: 16px;
  }
  .actionBtn {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}
