@import "@styles/variables";

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  a {
    padding: 10px 25px;
  }
}

.logo {
  width: auto;
  height: auto;
}
@media (max-width: $screen-width-md) {
  .logo {
    svg path {
      width: 100px;
    }
  }

  .dorpDownBtn {
    display: none !important;
  }
  .logoContainer {
    a {
      padding: 0;
    }
  }
}
