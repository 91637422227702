@import "@styles/variables";
.mobileSearch {
  display: none;
}
@media (max-width: $screen-width-md) {
  .mobileSearch {
    display: block;
  }
  .desktopSearch {
    display: none;
  }
}
.promoWrapper{
  @media (max-width: $screen-width-md) {
  margin: 0 -16px;
  padding: 0;
  }
}


.searchModal {
  position: relative;
  min-width: 370px;
  background-color: white;
  @media (max-width: $screen-width-xxl) {
    min-width: 300px;
  }
  @media (max-width: $screen-width-xl) {
    min-width: 230px;
  }
  @media (max-width: $screen-width-md) {
    min-width: auto;
    background-color: transparent;
  }
  @media (max-width: $screen-width-sm) {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background-color: $neutral-30;
    border-radius: 50px;
  }
  .searchResultWrapper {
    position: absolute;
    top: 100%;
    right: 0;
    max-height: 100vh;
    z-index: 999;
    border: 1px solid $neutral-30;
    width: 100%;
    box-shadow: 10px 30px 44px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    .searchButton {
      display: none !important;
    }
  }
  .mobileSearchField {
    display: none !important;
  }
  .searchField {
    border-radius: 0;
    input {
      width: 100%;
      padding: 5px 0;
      border: 0;
      &:focus-visible {
        box-shadow: none;
        outline: none;
      }
    }
    :global {
      .ant-input-suffix {
        display: flex;
        width: min-content;
        padding: 8px 4px;
      }
      .ant-input-clear-icon {
        height: 24px;
      }
      .ant-input-clear-icon-has-suffix {
        display: none;
      }
    }
    @media (max-width: $screen-width-lg) {
      :global .ant-input {
        padding-right: 0 !important;
      }
    }
  }
  .searchButton,
  .closeButton {
    display: none;
  }
}
.customWidth {
  width: 100%;
  position: relative;
}
.closeMenu {
  position: absolute;
  top: 10px;
  right: 10px;
}
.recentSearchText {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &:hover {
    background-color: $neutral-30;
  }
}
@media (max-width: $screen-width-md) {
  .searchModal {
    position: unset;
    .searchResultWrapper {
      border-radius: 0;
      margin-top: 0;
      padding: 16px;
      @media (max-width: $screen-width-mobile-lg) {
        width: 100%;
      }
    }

    .searchField {
      display: none !important;
    }
    .compareSearchField {
      display: flex !important;
      height: 33px;
      input {
        padding: 8px 12px 6px !important;
      }
    }
    .mobileSearchField {
      border-radius: 0;
      display: flex !important;
      input {
        font-size: $fs-base !important;
      }
      :global {
        .ant-input-suffix {
          width: min-content;
          display: flex;
          align-items: center;
          > svg {
            padding-left: 8px;
            margin-left: 8px;
            width: 36px;
            border-left: 1px solid rgba(137, 137, 137, 0.5);
          }
        }
      }
    }
    .searchButton {
      display: block !important;
      background-color: transparent !important;
      &:hover {
        opacity: 0.7;
      }
      &:focus {
        opacity: 1;
      }
    }
    .closeButton {
      display: flex !important;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 32px;
      &:hover {
        opacity: 0.7;
      }
      &:focus {
        opacity: 1;
      }
    }
  }
}
.searchItems {
  max-height: calc(100vh - 440px);
  overflow-y: auto;
  margin-right: 16px;

  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 50px;
    background-color: $neutral-30;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    border-radius: 50px;
  }

  .compareSearchProductsResult {
    cursor: pointer;
  }
  .menuItemWrapper {
    display: flex;
    margin: 8px 20px;
    border-bottom: 1px solid $neutral-30;
    &:hover {
      background-color: $neutral-30;
    }
    @media (max-width: $screen-width-sm) {
      margin-right: 0 !important;
    }
    &:last-child {
      border-bottom: 0;
    }

    .imgContainer {
      margin: auto 8px auto 0;
      min-width: 58px;
      min-height: 47px;
      object-fit: contain;
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title {
      text-transform: uppercase;
    }
    .categoryItem {
      background: $primary-light;
      border: 1px solid $jeans-blue;
      text-transform: uppercase;
      width: fit-content;
      display: inline-flex;
    }
  }
}
.menuHeader {
  position: relative;
  h2 {
    @media (min-width: $screen-width-md) {
      padding-top: 20px;
    }
  }
  button {
    background: transparent;
    border: 0;
    cursor: pointer;
  }
  .recentSearch {
    color: $primary-color;
  }
  .recentSearchHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $screen-width-md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .compareRecentSearchText {
    @media (max-width: $screen-width-md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.menuHeaderEnd {
  display: flex;
  justify-content: flex-end;
  button {
    background: transparent;
    border: 0;
    cursor: pointer;
  }
}

.searchResultWrapper {
  box-shadow: 10px 14px 64px rgb(0 0 0 / 15%);
  width: 100%;
  .closeButton {
    display: none !important;
  }

}

.searchPageResultWrapper {
  @media (max-width: $screen-width-md) {
    max-width: 412px;
    &.storeResult{
      max-width: none;
    }
    &:not(.noResultWrapper) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.compareSearchResult {
  @media (max-width: $screen-width-md) {
    .compareHistoryTitle {
      padding-left: 0;
    }
    padding-left: 16px;
    &:not(.noResultWrapper) {
      width: 100%;
      position: absolute;
      top: 0;
      height: fit-content;
    }
    max-width: unset;
    .compareMenuItemWrapper {
      margin: 0;
    }
  }
}
.PromoQuestionContainer{
  position: relative;
  @media (max-width: $screen-width-md) {
    padding: 0;
  }
}