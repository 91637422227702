@import "@styles/variables";
.shoppingList {
  .titleContainer {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 36px;
    border-bottom: 1px solid rgba(64, 75, 96, 0.4);
    align-items: center;
    .creatBtn {
      border: 0;
      padding: 0;
      font-size: 17px;
      font-weight: 700;
      color: $oxford-blue;
      span {
        display: flex;
        align-items: center;
      }
    }
    .addBtn {
      display: flex !important;
      justify-content: center;
      align-items: center;
      background-color: $oxford-blue !important;
      border-radius: 50% !important;
      border: none !important;
      width: 24px;
      height: 24px;
    }
  }
  .pageBtn {
    margin-bottom: 40px;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
    padding-top: 100px;

    button {
      width: 148px;
      height: 56px;
    }
  }
  .loaderContainer {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .noShoppingListAvailable {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.shopping-list-overlay {
  position: fixed;
  top: 0;
  right: 0;
  height: -webkit-fill-available;
  z-index: 200;
  width: 590px;
  box-shadow: -30px 24px 64px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (max-width: $screen-width-sm) {
    width: auto;
    top: auto;
    margin-top: 22px;
    padding: 24px 0px;
    &__header,
    &__items,
    label,
    &__footer__checkoutBtnWrapper {
      padding-left: 16px;
      padding-right: 16px;
    }
    h2 {
      font-size: $fs-h4;
    }
    span {
      font-size: $fs-sm;
    }
  }
  @media (max-width: $screen-width-mobile-lg) {
    width: 100%;
  }
  .closeIcon {
    background: $white-color;
  }
  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__items {
    max-height: 393px;
    overflow-y: auto;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    @media (max-width: $screen-width-mobile-lg) {
      position: fixed;
      bottom: 24px;
      margin: auto;
      width: 100%;
    }
    &__checkoutBtnWrapper {
      display: flex;
      gap: 16px;
      @media (max-width: $screen-width-sm) {
        border-top: 1px solid $neutral-30;
        box-shadow: 0 -16px 34px rgb(0 0 0 / 8%);
      }
    }
    label {
      display: flex;
      justify-content: space-between;
    }
    &__order {
      border-top: 1px solid rgba(181, 215, 253, 0.5);
      @media (max-width: $screen-width-sm) {
        margin-bottom: 32px;
      }
    }
    &__checkoutBtn {
      height: auto;
    }
  }
}
.successModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.shoppingNameInputError{
  input{
    background-color: $white-color;
  }
}
.saveBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .saveWishlistBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
  }
}
.formWrapper{
  width: 100%;
  justify-content: flex-end;
  flex-grow: 1;
  :global{
    .ant-form-item {
      margin-inline-end:0
    }
  }
  @media(max-width:$screen-width-md){
    justify-content: start;
  }
  @media(max-width:$screen-width-xs){
    width: auto;
  }
}
.searchFormInput{
  border-radius: 0;
  flex-grow: 1;
  :global{
    .ant-input{
      padding: 14px 6px !important;

      @media (max-width: $screen-width-md) {
        font-size: $fs-base !important;
      }
    }
  }
}
