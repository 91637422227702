@import "@styles/variables";
.accountInformation {
  height: 100%;
  h2 {
    color: $oxford-blue;
  }

  :global {
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $oxford-blue !important;
      }
    }
    .ant-tabs-ink-bar {
      background: $oxford-blue;
    }
    .ant-tabs-nav {
      &::before {
        border: 0;
      }
    }
  }
}
