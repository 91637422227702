@import "@styles/variables";

@mixin typeface($size, $weight) {
  font-size: $size;
  font-weight: $weight;
}

@function pxToRem($pxSize) {
  @return $pxSize / 16 + rem;
}

@mixin fonts() {
  $font-size-list: (
    "xxs": $fs-xxs,
    "xs": $fs-xs,
    "sm": $fs-sm,
    "base": $fs-base,
    "h6": $fs-h6,
    "h5": $fs-h5,
    "h4": $fs-h4,
    "h3": $fs-h3,
    "h2": $fs-h2,
    "h1": $fs-h1,
    "lg": $fs-lg,
    "xl": $fs-xl,
    "xxl": $fs-xxl,
    "xxxl": $fs-xxxl,
  );

  $font-weight-list: (
    "regular": 400,
    "medium": 500,
    "semibold": 600,
    "bold": 700,
    "bolder": 800,
    "black": 900,
  );

  @each $fsKey, $fsVal in $font-size-list {
    @each $fwKey, $fwVal in $font-weight-list {
      .font-#{$fwKey}-#{$fsKey} {
        @include typeface($fsVal, $fwVal);
      }
    }
  }
}

@mixin line-heights(){
  $line-heights: ("1": 1, "sm": 1.2, "md": 1.25, "lg": 1.5, "xl": 2);

  @each $key, $val in $line-heights{
    .lh-#{$key} {
      line-height: #{$val};
    }
  }
}

@include fonts();
@include line-heights();