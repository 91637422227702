@import "@styles/variables";

.left_container {
  background: $secondary-yellow
    url("https://22338452.fs1.hubspotusercontent-na1.net/hubfs/22338452/images_storefront/registerForm.png.png?auto=webp&format=png")
    center bottom no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  .imageContainer {
    padding-left: 80px;
    @media (max-width: $screen-width-lg) {
      padding-left: 40px;
    }
  }
}
.yourSupply {
  margin-top: 64px;
  width: 200px;
  @media (max-width: $screen-width-lg) {
    margin-top: 32px;
    font-size: 50px;
  }
}
.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-in-label {
  margin-bottom: 16px;
  @media (max-width: $screen-width-md) {
    margin-bottom: 8px;
  }
}
.ml_auto {
  margin-left: auto;
  display: block;
}
.height_50 {
  height: 48px;
}
.login-title {
  font-weight: 800;
  font-size: 40px;
}
.sign-up-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-up {
  height: 23px;
}
.border_radius {
  border-radius: 0;
}
.text-align {
  text-align: center;
}
.google-icon-container {
  cursor: pointer;
  margin: auto;
  height: 48px;
  width: 200px;
  svg {
    border: 1px solid $neutral-30;
    border-radius: 50px;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.outer-container {
  :global {
    .ant-input-suffix {
      div {
        height: 24px;
      }
    }
  }
}
@media (max-width: $screen-width-md) {
  .login-title {
    font-size: $fs-h4;
    color: $black-color;
  }
  .form-container {
    padding: 0;
  }
}
