@import "@styles/variables";
.filtersMenu {
  z-index: 103;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 550px !important;
  max-width: 100%;
  .iconBttnWrapper {
    display: flex;
    border: 1px solid $oxford-blue;
    &:not(:disabled):hover {
      background-color: $primary-light;
    }
  }
  :global {
    .ant-drawer-footer {
      border-top: 0;
    }
    .ant-drawer-header {
      border-bottom: 0;
      .ant-drawer-title {
        font-size: 46px;
        font-weight: 700;
      }
    }
  }
  .filterBttn {
    height: auto;
  }
  .pricesWrapper {
    .dangerBorder {
      border: 1px solid $tertiary-pink;
    }
    display: flex;
    justify-content: space-between;
    :global {
      .ant-input-number-group-wrapper {
        .ant-input-number-wrapper {
          .ant-input-number {
            .ant-input-number-input-wrap {
              .ant-input-number-input {
                height: 48px;
              }
            }
          }
        }
      }
    }
  }
}
.myOrdersWrapper {
  .completed {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .header {
    align-items: center;
    gap: 16px;
    .actionWrapper {
      display: flex;
      align-items: center;
      .filterWrapper {
        display: flex;
        gap: 8px;
        &:active {
          background-color: $white-color;
        }
      }
      .searchInput {
        border-radius: 0;
        width: 246px;
      }
    }
  }
  :global {
    @media (max-width: $screen-width-md) {
      .ant-input {
        font-size: $fs-base !important;
      }
    }
    .ant-pagination {
      margin: 150px 0 40px 0 !important;
      justify-content: center;
      .ant-pagination-item-active {
        border-radius: 50%;
        background-color: $oxford-blue !important;
      }
    }
    .ant-table-cell {
      background-color: rgba(213, 230, 249, 0.2) !important;
      border-radius: 0;
    }
    .ant-table-thead {
      .ant-table-cell {
        font-size: 12px;
        font-weight: 700;
        &::before {
          background-color: unset !important;
        }
      }
    }
    .ant-tabs-nav {
      margin-bottom: 0;
      padding-left: 16px;
      .ant-tabs-tab-btn {
        padding-bottom: 8px;
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: $oxford-blue;
        }
      }
      &::before {
        border-bottom: 0;
      }
      .ant-tabs-tab-btn {
        padding: 0;
      }
      .ant-tabs-ink-bar {
        background: $oxford-blue;
      }
    }
  }
  .myOrdersWrapper {
    .myOrdersTable {
      .orderDate{
        width: 120px;
        white-space: nowrap;
      }
      .quantity {
        text-align: center;
      }
      .completed,
      .viewDetails {
        &:hover {
          color: $oxford-blue;
        }
        &:active {
          background-color: $white-color;
        }
      }
    }
  }
  .sortByDateContainer {
    display: flex;
    justify-content: flex-end;
  }
}
.orderDetailsWrapper {
  .productLink {
    &:hover {
      color: inherit;
    }
  }
  .productName {
    max-width: 600px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .mixedPallet {
    border: 2px solid $neutral-30;
    @media (min-width: $screen-width-xs) {
      margin-left: 16px !important;
      margin-right: 16px !important;
    }
    border-radius: 12px;
    gap: 24px;
  }
  .divider {
    margin: 0 16px;
    top: 0;
    height: auto;
    background: $neutral-50;
    opacity: 0.2;
  }
  .noBorder {
    border: 0;
    display: flex;
    align-items: center;
    div {
      display: inline-flex;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
  }
  .reorderBtn {
    height: auto;
    &:not(:disabled):not(:hover) {
      background-color: $primary-color;
      color: $white-color;
    }
  }
  .trackingID {
    div {
      display: flex;
      align-items: center;
    }
  }
}

.ordersMobileView {
  background: rgba(213, 230, 249, 0.2);
  padding: 24px 16px 8px;
  .noDataWrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .myOrdersRow {
    color: $neutral-70;
    .trimText {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $neutral-70;
    }
  }
  .titleSection {
    padding: 0;
    display: flex;
    gap: 12px;
    padding-bottom: 24px;
    justify-content: space-between;
  }
}