@import "@styles/variables";
.dashboardWrapper {
  .titleSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: auto;
    }
  }
  .joinHaggle{
      background: rgb(213, 230, 249, 0);
  }
  @media (max-width: $screen-width-md) {
    h2 {
      font-size: $fs-h4;
      font-weight: $bolder-font-weight;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    @media (max-width: $screen-width-md) {
      justify-content: space-between;
    }
    a {
      border-bottom: 2px solid $primary-color;
      line-height: 17px;
      &:hover {
        border-bottom: 0;
        color: $oxford-blue;
      }
      &:active {
        color: $oxford-blue;
      }
    }
    .viewAllButton {
      height: 17px;
    }
  }
  .shoppingLists {
    background: rgb(213, 230, 249, 0.2);
    :global {
      .ant-form-item-control {
        background-color: $white-color;
      }
    }
  }
  .viewAllButton {
    @media (max-width: $screen-width-md) {
      font-size: $fs-xs;
      font-weight: $semi-font-weight;
    }
  }
  .btnWrapper {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    button {
      padding: 8px 20px;
      width: 50%;
      @media (max-width: $screen-width-md) {
        padding: 8px 12px;
      }
    }
  }
  .productNum {
    color: $neutral-70;
  }
}
