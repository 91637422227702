@import "@styles/variables";

.MixedPalletMobile {
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

  div {
    display: inline-block;
    vertical-align: middle;
  }
}

.menuContainer {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 201;
  max-height: 100vh;
  background-color: $white-color;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px;
  li {
    div {
      padding-left: 16px;
    }
  }
}
.mainMenu {
  overflow-y: scroll;
  height: calc(100vh - 128px);
  padding-bottom: 150px;
  ul {
    li {
      padding-left: 16px;
    }
    background: unset !important;
    .levelStyle {
      padding: 0 !important;
      padding: 0 16px !important;
      span {
        border-bottom: 1px solid;

        padding-left: 29px;
        a {
          display: flex;
        }
      }
      :global {
        .ant-menu-item-only-child {
          padding-left: 16px !important;
        }
        .ant-menu-title-content {
          margin-bottom: unset;
        }
      }
    }
  }

  :global {
    .ant-menu-submenu-open {
      .ant-menu-submenu-open {
        display: block !important;
        ul {
          padding-left: 0 !important;
          li {
            padding-left: 72px;
            margin: 0;
            height: auto;
            .ant-menu-title-content {
              padding: 24px 0 24px 29px;
              height: auto;
              line-height: 100%;
            }
          }
        }
      }
      li:last-child {
        .ant-menu-title-content {
          border-bottom: none !important;
          margin-bottom: 24px;
        }
        li {
          .ant-menu-title-content {
            border-bottom: 1px solid #e7e7e7 !important ;
            margin-bottom: 0;
          }
        }
      }
    }

    .ant-menu-submenu-title {
      padding: 0 16px !important;
      margin: 0 !important;
      width: 100%;
      background-color: unset !important;
      height: auto !important;
      .ant-menu-title-content {
        span {
          display: flex;
          align-items: baseline;
          div {
            margin-left: auto;
            padding-left: 0;
          }
        }
      }
    }
    .ant-menu-submenu-open {
      ul {
        .ant-menu-title-content {
          border-bottom: 1px solid $neutral-30 !important;
          padding-bottom: 24px;
          a {
            div {
              margin-left: auto;
              padding-left: 0;
            }
          }
          span {
            padding-left: 29px;
            height: auto;
            line-height: 100%;
          }
        }
      }
    }
    .ant-menu-submenu-open > :first-child {
      pointer-events: none !important;
      font-size: $fs-h4 !important;
      line-height: 29.05px !important;
      color: $oxford-blue !important;
      font-weight: 600 !important;
      background-color: unset !important;
      .ant-menu-title-content {
        border-bottom: none !important;
        padding-left: 29px;
        span {
          padding-left: 0;
          white-space: break-spaces;
        }
        div {
          display: none;
        }
      }
    }
    li {
      &.ant-menu-submenu-inline {
        display: none;
      }
      &.ant-menu-submenu-open {
        display: block;
        li {
          display: block;
        }
      }
    }
  }

  .backButton > :first-child {
    pointer-events: auto !important;
    font-size: $fs-h5 !important;
    line-height: 24.2px !important;
    padding: 0 16px !important;
    margin: 0;

    div {
      padding-left: 0 !important;
      display: inline !important;
      margin-left: 0 !important;
    }
  }
  i {
    display: none !important;
  }
  .hideTitle {
    :global {
      .ant-menu-submenu-title {
        display: none;
        height: auto !important;
        line-height: 100% !important;
        &:hover {
          background-color: unset !important;
        }
      }
      ul {
        .ant-menu-submenu-title {
          display: block;
        }
      }
    }

    .back {
      pointer-events: auto !important;
    }
    .level2Title {
      padding-left: 29px !important;
    }
  }
}
.headerStyle {
  flex-wrap: wrap;
  :global {
    div {
      display: block !important;
    }
  }
  span {
    padding-left: 0 !important;
  }
}

.mainMenuButton {
  line-height: 24.2px;
  font-size: $fs-h5;
  font-weight: 600;
  :global {
    i {
      display: none;
    }
    .ant-menu-submenu-title {
      padding-left: 16px !important;
      background-color: unset !important;
    }
    .ant-menu-title-content {
      span {
        display: flex;
        gap: 4px;
        div {
          width: 24px;
          height: 24px;
          padding-left: 6px !important;
          margin-left: 0 !important;
        }
      }
    }
  }

  div {
    padding-left: 0 !important;
    margin-bottom: 0 !important;
  }
}

.locatorContainer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.viewButton {
  padding: 0 !important;
  height: 120px !important;
  bottom: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  span {
    padding: 0 16px 0 !important;
    border-top: 1px solid $neutral-30;

    button {
      width: 100%;
      height: 48px;
      margin-top: 24px;
      background-color: $oxford-blue;
      border-color: $oxford-blue;
    }
    span {
      border-top: unset;
    }
  }
}
@media (max-width: $screen-width-md) {
  .mainMenu {
    padding-top: 12px;
    :global {
      .ant-menu-submenu {
        margin-bottom: 12px;
        line-height: 40px !important;
      }
      .ant-menu-item-only-child {
        padding-left: 16px !important;
        margin: 0;
        margin-bottom: 12px;
      }
    }
  }
}
