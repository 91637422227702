@import "@styles/variables";
.itemViewSection {
  .noBorder {
    border: 0;
    :global {
      span {
        display: flex;
      }
    }
  }
  .search {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .titleSection {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
    border-bottom: 1px solid rgba(64, 75, 96, 0.4);
    row-gap: 12px;
    flex-wrap: wrap;
    .trimSection {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    button {
      border: 0;
      background: transparent;
      height: fit-content;
      cursor: pointer;
      &:active {
        background-color: transparent;
      }
    }
    .shareBtn > div {
      display: none;
    }
    .editNameBtn {
      div {
        border: unset;
        background: $white-color;
        width: unset;
        height: unset;
      }
    }
  }
  .emptyCheckboxContainer {
    width: 100%;
  }
  .backToShoppingListPage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70px;
    border-bottom: 0;
  }
  .settingContainer {
    padding: 20px 0;
    display: flex;
    gap: 13px;
    align-items: center;
    border-bottom: 1px solid rgba(64, 75, 96, 0.4);
    .selectAllForMobile {
      display: none;
    }
    .settingSection {
      display: flex;
      gap: 13px;
      align-items: center;
      flex-wrap: wrap;
    }
    @media (max-width: $screen-width-xs) {
      .settingSection{
        gap: 8px;
      }

      .selectAll {
        display: none;
      }
      .selectAllForMobile {
        display: flex;
        flex-shrink: 0;
      }
    }
    .btnGroup {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-grow: 1;
      :global {
        .ant-form-item {
          width: 100%;
          max-width: 540px;
        }
      }
    }
    @media (max-width: $screen-width-md) {
      gap: 24px;
      align-items: baseline;
      flex-direction: column-reverse;
      .settingSection {
        width: 100%;
      }
      .btnGroup {
        justify-content: space-between;
        width: 100%;
        .addBtn {
          width: 50%;
        }
      }
    }
    @media (max-width: $screen-width-mobile-lg) {
      .btnGroup {
        gap: 8px;
        .addBtn {
          padding: 6px 8px;
        }
      }
    }
    @media (max-width: $screen-width-mobile-md) {
      .btnGroup {
        .addBtn {
          width: auto;
        }
      }
    }
  }
  .itemsPerPageContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: $screen-width-md) {
      justify-content: center;
    }
  }
  .buttomContainer {
    padding-top: 80px;
    align-items: baseline;
    margin-bottom: 50px;
    :global {
      .ant-col {
        text-align: center;
      }
    }
    @media (max-width: $screen-width-md) {
      padding-top: 20px;
      flex-direction: column;
      align-items: center;
    }
  }
  .image {
    display: flex;
    margin: auto;
  }
  .discount {
    text-decoration: line-through;
  }
  .category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      vertical-align: middle;
      padding-right: 6px;
    }
  }

  .editNameForm {
    display: flex;
    justify-content: center;
    :global {
      .ant-form-item:first-child {
        margin-right: 0;
      }
    }
  }
}
.emptyWishlist {
  height: 300px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.card__wrapper {
  border: 1px solid rgba(64, 75, 96, 0.4);
}
.productWrapper{
  @media (max-width: ($screen-width-xl - 1)) and (min-width: $screen-width-md){
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
