@import "@styles/variables";
.manageAccountSelect {
  :global {
    .ant-select-selector {
      height: 48px !important;
      display: flex;
      align-items: center;
    }
  }
}
.knowledgeHubSelect {
  :global {
    width: 100%;
    .ant-select-selector {
      height: 48px !important;
      border-radius: 0px;

      input {
        height: 48px !important;
      }
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }
  }
}
