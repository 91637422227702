@import "@styles/variables";

.container,
.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.container {
  max-width: 1600px;
}

@mixin make-container() {
  $breakpoints: (
    $screen-width-sm: 30px,
    $screen-width-lg: 40px,
    $screen-width-xl: 76px,
  );

  @each $breakpoint, $padding in $breakpoints {
    @media (min-width: $breakpoint) {
      .container {
        padding-right: $padding;
        padding-left: $padding;
      }
    }
  }
}

@include make-container();
