@import "@styles/variables";

.overlay{
    position: fixed;
     width: 100vw;
     height: 100vh;
     background: transparent;
     border: 0;
     left: 0;
     right: 0;
     bottom: 0;
     top: 0;
     z-index: 200;
     &.bgOverlay{
      background: $oxford-blue;
      opacity: 0.4;
      z-index: 501;
     }
     @media (max-width: $screen-width-xs) {
        display: none;
        &.mobileLocator{
          display: block;
        }
      }
 }