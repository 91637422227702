@import "@styles/variables";

.Collapse{
    border-bottom: 1px solid $primary-color;
}
.accordion-disabled{
    background: $neutral-30;
    color: $neutral-70;
    border-bottom: 1px solid $neutral-50;
}
