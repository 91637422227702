@import "@styles/variables";
header {
  .logInModal {
    display: none;
    .logInBttn{
      min-height: 40px;
    }
  }
  @media (max-width: $screen-width-md) {
    .logInModal {
      display: block;
    }
  }
}

.Modal {
  :global {
    top: 272px;
    .ant-modal-content {
      padding: 32px 16px;
      border-radius: 0;
      .ant-modal-header {
        margin-bottom: 6px;
        .ant-modal-title {
          font-weight: $bolder-font-weight;
          line-height: 34px;
          font-size: $fs-h3;
          text-align: center;
        }
      }
      .ant-modal-body {
        font-size: $fs-sm;
        font-weight: $medium-font-weight;
        text-align: center;
      }
      .ant-modal-footer {
        margin-top: 20px;
      }
    }
    .ant-btn {
      width: 100% !important;
      margin-left: 0;
      height: 48px;
      font-weight: $bold-font-weight;
      line-height: 19px;
      padding: 16px;
    }
    .ant-btn-primary {
      margin-left: 0 !important;
    }
  }
}

