@import "@styles/variables";

.tabMenuContainer {
  position: absolute;
  z-index: 999;
  background: $white-color;
  top: 96px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px;

  :global {
    .ant-tabs-nav-wrap {
      background-color: $primary-lighter !important;
      .ant-tabs-tab {
        padding: 0 !important;
        padding: 24px !important;
        margin: 0 24px !important;
        font-size: 18px !important;
        color: $oxford-blue !important;
        font-weight: $semi-font-weight;
        @media (min-width: $screen-width-sm) {
          margin: 0 10px !important;
        }
        &.ant-tabs-tab-active {
          div {
            color: $white-color !important;
          }
          background-color: $oxford-blue;
        }
        &.ant-tabs-tab-disabled {
          font-size: 32px !important;
          font-weight: bold;
          color: $black-color !important;
          cursor: context-menu !important;
          padding-top: 40px !important;
          padding-right: 45px !important;
          padding-bottom: 30px !important;
          div:active {
            color: unset !important;
          }
        }
      }
      .ant-tabs-ink-bar {
        display: none !important;
      }
    }
  }
  @media (max-width: $screen-width-md) {
    display: none;
  }
  @media (min-width: $screen-width-sm) {
    max-width: calc(100% - 80px);
  }
  @media (min-width: $screen-width-lg) {
    max-width: calc(100% - 160px);
  }
}

.navbarContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  .firstContainer {
    display: flex;
    gap: 24px;
    @media (max-width: $screen-width-lg) {
      gap: 8px;
    }
    @media (max-width: $screen-width-md) {
      gap: 16px;
    }
  }
  .desktopMenu {
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 200;
    @media (max-width: $screen-width-lg) {
      gap: 8px;
    }
    @media (max-width: 1024px) {
      gap: 2px !important;
    }
    button {
      font-size: $fs-base;
      height: 100%;
      border: 0;
      background: transparent;
      display: flex;
      align-items: center;
      padding: 8px 0;
      color: $oxford-blue;
      cursor: pointer;
      &:hover {
        box-shadow: inset 0 -5px $oxford-blue;
      }
      div {
        margin-top: 6px;
      }
      span {
        display: flex;
      }
    }
    .menuCategoryBtn {
      box-shadow: inset 0 -5px $oxford-blue;
      span {
        padding-top: 5px;
      }
    }
    @media (max-width: $screen-width-md) {
      display: none;
    }
  }
  .secondContainer {
    display: flex;
    align-items: center;
    gap: 20px;
    &.haggleContainer {
      gap: 0;
    }
    @media (max-width: $screen-width-md) {
      max-width: fit-content !important;
    }

    @media (max-width: $screen-width-md) {
      max-width: 35%;
    }
    @media (max-width: $screen-width-lg) {
      gap: 12px;
    }
  }
}
.itemsWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: $screen-width-lg) {
    gap: 0.5rem;
  }
}
.hide {
  display: none;
}
.headerBtn {
  width: 95px;
  height: 49px;
  font-weight: $bold-font-weight;
  @media (max-width: $screen-width-lg) {
    width: auto;
  }
}

.menuBtn {
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    opacity: 1;
  }
  @media (max-width: $screen-width-md) {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: $oxford-blue !important;
    border-radius: 50% !important;
    border: none !important;
    div {
      padding-top: 5px;
    }
  }
  display: none !important;
}

.logoImg {
  padding: 22px 0;
  flex-shrink: 0;
}
.alertContainer {
  flex-wrap: wrap;
  .alert-button {
    height: 22px;
    display: block;
    margin: auto;
  }
  @media (max-width: $screen-width-sm) {
    :global {
      .ant-alert-action {
        order: 2;
        flex-basis: 100%;
      }
    }
  }
}
.wishListIcon {
  div {
    display: flex;
  }
  &:hover{
    opacity: 0.7;
  }
  div {
    display: flex;
    align-items: center;
  }
}
@media (max-width: $screen-width-md) {
  .menu,
  .signLink {
    display: none;
  }
  .navbarWrapper {
    padding: 0;
  }
  .navbarContainer {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .logoImg {
    padding: 0;
    width: 126px;
  }
  .itemsWrapper {
    gap: 12px;
  }
  .secondContainer {
    width: auto;
  }
}
