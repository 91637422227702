@import "@styles/variables";

.footerContent {
  background: linear-gradient(to right, $primary-lighter, $footer-bg-right);
  @media (max-width: $screen-width-lg) {
    background: linear-gradient(to bottom, $primary-lighter, $footer-bg-right);
  }
  .footerRowWrapper {
    .leftSide {
      padding: 70px 54px 62px 0px;

      @media (max-width: $screen-width-lg) {
        padding: 40px 48px 62px 0px;
      }
      @media (max-width: $screen-width-sm) {
        padding-bottom: 34px;
      }
      .privacyLink {
        border-bottom: 2px solid $primary-color;
        @media (max-width: $screen-width-sm) {
          font-size: $fs-xxs;
        }
        &:hover {
          border-bottom: none;
          color: $oxford-blue;
        }
      }
    }
  }
}

.description {
  @media (max-width: $screen-width-sm) {
    font-size: $fs-xs;
    margin-bottom: 16px;
  }
}

.rightSide {
  padding: 80px 0px 60px 55px;
  @media (max-width: $screen-width-lg) {
    padding-top: 32px;
    padding-bottom: 40px;
    padding-left: 0;
  }
}
.linkCol {
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  li {
    a {
      &:hover {
        border-bottom: 2px solid $oxford-blue;
        color: $black-color;
      }
    }
  }
}
.customInput {
  border-radius: 0px;
  border-color: $neutral-30;
  border-right-color: $white-color;
  height: 48px;
  border-width: 1px;
  border-style: solid;
  padding: 3px 11px;
  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
  }
}
.socialIconsWrapper {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: $screen-width-sm) {
    justify-content: center;
  }
  .SocialIconCol {
    margin-left: 11px;
  }
}
.copyRightWrapper {
  text-align: right;
  @media (max-width: $screen-width-sm) {
    text-align: center;
    margin-top: 19px;
  }
}
.bttn {
  background-color: $white-color;
  border-color: $neutral-30;
  border-left-color: $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  &:not(:disabled):hover {
    color: $neutral-70;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    svg {
      fill: $white-color;
    }
  }
}
.hubSpotForm {
  height: 130px;
  :global {
    .submitted-message {
      color: green;
    }
  }
  form {
    display: flex;
    flex-direction: row;
    :global {
      .hs_error_rollup {
        display: none;
      }
      .hs-error-msgs {
        padding-left: 12px;
        list-style: none;
        .hs-error-msg {
          font-size: $fs-xxs;
          color: $tertiary-pink;
        }
      }
      .hs_email {
        width: 290px;
        .input {
          &:focus-visible {
            outline: none;
          }
        }
        input {
          padding: 3px 11px;
          border: 1px solid $neutral-30;
          width: 100%;
          border-right: none;
          &:hover,
          &:focus,
          &:focus-visible {
            border: 1px solid $secondary-blue;
            outline: none;
          }
        }
      }
      .actions {
        position: relative;
        input {
          background: transparent;
          color: transparent;
          border: 1px solid $neutral-30;
          border-left: none;
          padding: 6px 15px;
          width: 54px;
          position: relative;
          z-index: 99;
          cursor: pointer;
        }
        &::after {
          content: url("/sent.svg");
          display: flex;
          align-items: center;
          justify-content: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          padding: 6px 15px;
          line-height: 0.5;
          background: $white-color;
        }
        &:hover{
          &::after {
            filter: invert(1);
          }
        }
      }
    }
    input {
      height: 48px;
    }
  }
}
