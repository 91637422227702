@import "@styles/variables";

.actionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lockIcon{
  display: flex;
}
.guestLabel {
  text-align: center;
}
.customButton {
  white-space: normal;
  height: auto;
}
.deliveredPrice,
.casesPerPallet {
  white-space: nowrap;
}
.featuredProductCard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 640px;
  height: auto;
  background-color: $white-color;
  border: 3px solid #e7e7e7;
  padding: 12px 12px 0 12px;

  .itemsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .imagesWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .featuredProductCardDetails {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .actionsContainer {
    width: 100%;
    border-top: 3px solid $neutral-30;
    background-color: $white-color;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px;
    box-shadow: 0px -16px 34px rgba(0, 0, 0, 0.08);
  }
}

.carouselProductCard {
  width: auto !important;
  &.mixAndMatchCarouselProductCard {
    @media (min-width:$screen-width-xs ) and ( max-width: $screen-width-md) {
      margin-inline-end: 10px !important;
    }
  }
}

.productCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 343px;
  height: 100%;
  background-color: $white-color;
  border: 2px solid $neutral-30;
  padding: 12px 12px 0 12px;

  a{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .cardInfoWithImg{
    flex: 1;
    a{
      max-height: 216px;
    }
  }

  .cardInfo {
    @media (min-width: $screen-width-md) {
      height: 70px;
    }
  }
  .vendorCardInfo {
    @media (min-width: $screen-width-md) {
      height: auto;
    }
  }

  @media (max-width: $screen-width-mobile-md) {
    width: 100%;
  }
  @media (max-width: $screen-width-md) {
    .priceWrapper,
    .cardInfoWithImg,
    .cardHeader,
    .actionsContainer {
      padding-left: 3px;
      padding-right: 3px;
    }
    .priceWrapper{
      flex-wrap: wrap;
      row-gap: 16px;
      margin-bottom: 16px;
    }
    .cardInfoWithImg {
      display: flex;
      .imageWrapper{
        margin-right: 12px;
      }
      .cardInfo {
        display: flex;
        flex-direction: column;
      }
    }
    .title {
      font-size: $fs-base;
    }

    .itemWrapper {
      height: fit-content;
      width: auto !important;
      span {
        &:first-child {
          line-height: 15px;
        }
        &:last-child {
          line-height: 17px;
        }
      }
      .value {
        font-size: $fs-sm !important;
      }
    }

    :global {
      .ant-tag {
        padding: 5px 8px !important;
        font-size: $fs-xxs !important;
        font-weight: $bold-font-weight;
        height: fit-content !important;
        line-height: 150% !important;
      }
    }
  }

  .actionsContainer {
    position: absolute;
    width: 100%;
    border-top: 3px solid $neutral-30;
    background-color: $white-color;
    bottom: 0px;
    left: 0px;
    display: none;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 10px;
    box-shadow: 0px -16px 34px rgba(0, 0, 0, 0.08);
    .compareBtn {
      margin: auto;
      padding-left: 0;
      padding-right: 0;
    }
  }
  a:hover {
    color: $oxford-blue;
  }
}

.productCard.cardDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.recentlyViewedCard {
  width: auto;
}
.productSectionClass {
  width: auto;
}

.productVendorClass {
  width: 267px;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  max-width: 100%;

  .cardInfoWithImg {
    gap: 8px;
  }

  @media (max-width: $screen-width-md) {
    width: 100%;
    cursor: pointer;

    .cardInfo {
      justify-content: center;
    }

    .imageWrapper {
      width: 122px;
      max-width: 122px;
      margin: 0;
    }

    .actionsContainer {
      flex-direction: row;
      column-gap: 10px;
      flex-wrap: wrap;
      justify-content: center !important;

      .actionBtn {
        max-width: 46%;
      }
    }
  }
}

.cardHeader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.imageWrapper {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  height: 200px;
  @media (max-width: $screen-width-md) {
    height: auto;
    max-height: 200px;
  }

  img {
    height: auto;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    display: flex;
    margin: auto;
  }
  @media (max-width: $screen-width-md) {
    width: 106px;
  }
}
.sharedImageWrapper {
  @media (max-width: $screen-width-md) {
    object-fit: contain;
  }
}
.completeProfileBtn {
  white-space: unset;
  height: auto;
}
.tagsSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 30px;
  .haggleAndWishlistWrapper{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  @media (max-width: $screen-width-md) {
    margin-bottom: 5px;
    min-height: 26px;
  }
}
.topButton {
  display: flex;
  align-items: center;
  align-items: center;
}
.category {
  color: $primary-color;
  font-style: normal;
  font-weight: $semi-font-weight;
  font-size: $fs-xxs;
  line-height: 150%;
  text-transform: uppercase;
}

.title {
  color: $oxford-blue;
  font-style: normal;
  font-weight: $medium-font-weight;
  font-size: $fs-base;
  max-height: 70px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardDisabled {
  cursor: not-allowed;
  background-color: #f9f9f9;
  filter: grayscale(100);
}

.priceWrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 8px;

  .itemWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      font-style: normal;
      font-weight: $medium-font-weight;
      font-size: 10px;
      line-height: 1.5;
      color: $neutral-70;
    }

    .value {
      font-weight: 700;
      font-size: $fs-h6;
      line-height: 24px;
      color: $oxford-blue;
      text-align: center;
      flex-grow: 1;
    }
  }
  .itemWrapperForMobile {
    display: none;
  }

  @media (max-width: $screen-width-md) {
    .itemWrapperForMobile {
      display: flex;
      .sizeWrapper{
        display: flex;
        background: unset;
        padding: 0;
        margin: 0;
        gap: 0;
        .itemWrapper{
          flex-direction: column;
        }
      }
    }
    .divider {
      margin-left: 5px;
      margin-right: 5px;
      flex-shrink: 0;
    }
  }
}

.sizeWrapper {
  margin: 16px -12px 0 -12px;
  display: flex;
  flex-direction: row;
  background-color: #f1eae5;
  gap: 8px;
  padding: 8px 12px;
  max-height: 37px;

  @media (max-width: $screen-width-md) {
    display: none;
  }
  .itemWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;

    span {
      font-style: normal;
      font-weight: $regular-font-weight;
      font-size: 11px;
      line-height: 150%;
      color: $neutral-70;
      white-space: nowrap;
    }

    span:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .value {
      font-weight: $semi-font-weight;
      font-size: 11px;
      line-height: 150%;
      color: $oxford-blue;
    }
  }
}

.vendorSizeWrapper {
  display: flex;
  max-height: 60px;
  .itemWrapper{
    width: auto;
  }
  @media(max-width:$screen-width-xxl){
    flex-wrap: wrap;
    gap: 0;
    column-gap: 8px;
  }
}

.divider {
  background-color: $neutral-60;
  opacity: 0.3;
  width: 1px;
  flex-shrink: 0;
}
.compareWrapper {
  width: 50%;
  text-align: center;
  .compareBtn{
    width: auto !important;
  }
}

.productCard:not(.cardDisabled):hover {
  .actionsContainer {
    display: flex;
    .btnStyle {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      button, .compareWrapper {
        width: 50%;
        justify-content: center;
        gap: 0;
        align-items: center;
        margin: auto;
      }
    }
    .compareStyle {
      margin: auto;
    }
  }
}

@media (max-width: $screen-width-md) {
  .productCard {
    .actionsContainer {
      position: unset;
      display: flex;
      box-shadow: unset;
      justify-content: space-between;
      padding: 16px 12px;
      .btnStyle {
        display: flex;
        gap: 16px;
        justify-content: space-between;
        button {
          width: 50%;
          justify-content: center;
          gap: 0;
          align-items: center;
          margin: auto;
          min-height: 40px;
        }
      }
      .compareStyle {
        margin: auto;
        padding: 0;
      }
      button {
        padding: 9px 25px;
        height: auto;
        font-size: $fs-xs;
        font-weight: $bold-font-weight;
      }
    }
  }
}

.addToPallet {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;

  svg {
    margin-right: 5px;
  }
}
