@import "@styles/variables";
.myOrders , .myOrdersMobile{
  background: rgb(213, 230, 249, 0.2);
  .noDataWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .titleSection {
    display: flex;
    gap: 12px;
    align-items: center;
    button {
      height: auto;
    }
  }
  .viewAllButton{
    height:17px;
    @media (max-width: $screen-width-md) {
      font-size: $fs-xs;
      font-weight: $semi-font-weight;
    }
  }
  .tableWrapper{
    .orderData{
      width: 120px;
      white-space: nowrap;
    }
    @media (max-width: $screen-width-sm) {
        margin-left: 0;
        margin-right: 0;
    }
  }
  :global {
    .ant-table {
      background-color: unset;
      .ant-table-thead {
        tr {
          border-radius: 0;
        }
        th {
          background-color: unset;
          font-weight: $medium-font-weight;
          font-size: $fs-xs;
          color: $neutral-70;
          padding: 0 0 5px;
          &::before {
            content: unset !important;
          }
        }
      }
      .ant-table-row {
        td {
          font-size: 14px;
          font-weight: 500;
          padding: 16px 12px 12px 0;
        }
        .orderIdStyle {
          font-weight: 700;
        }
        .viewButton {
          button {
            width: 95px;
          }
        }
      }
      .ant-table-content {
          overflow-x: hidden !important;
      }
    }
  }
  @media (max-width: $screen-width-sm) {
    padding: 24px 16px 8px;
    .titleSection {
      padding: 0;
      padding-bottom: 24px;
      justify-content: space-between;
    }
  }

  .colStyle {
    display: flex;
    flex-direction: column;
    span {
      font-weight: $medium-font-weight;
      font-size: $fs-sm;
      line-height: 21px;
      color: $oxford-blue;
    }
    .title {
      font-weight: $semi-font-weight;
      font-size: $fs-xxs;
      color: $neutral-70;
      line-height: 15px;
    }
  }
  .buttonStyle {
    width: 95px;
  }
  .rowStyle {
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  }
}
.myOrdersMobile{
  display: none;
}

@media (max-width: $screen-width-sm) {
  .myOrdersMobile{
  display: block;
  .myOrdersRow{
    color: $neutral-70;
    .trimText{
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $neutral-70;
    }
  }
  }
  .myOrders{
    display: none;
  }
 }