@import '@styles/variables';

.tag{
    font-size: $fs-xs;
    display: inline-flex !important;
    border-radius: 0px;

    svg{
        vertical-align: middle !important;
        margin-right: 5px;
    }
}
.tag-green{
    background-color: $tertiary-green !important;

    svg{
        path{
            fill: $oxford-blue !important;
        }
    }
}

.tag-light{
    background-color: $primary-light !important;
    border: 1px solid $jeans-blue !important;
    color: $oxford-blue !important;
    
    svg{
        path{
            fill: $oxford-blue !important;
        }
    }
}

.tag-dark{
    background-color: $oxford-blue !important;
    color: $white-color !important;

    svg{
        path{
            fill: $white-color !important;
        }
    }
}

.tag-outline{
    background-color: $white-color !important;
    border: 1px solid $oxford-blue !important;

    svg{
        path{
            fill: $oxford-blue !important;
        }
    }
}

.tag-primary{
    background-color: $primary-color !important;
    color: $white-color !important;

    svg{
        path{
            fill: $white-color !important;
        }
    }
}

.tag-xs{
    font-size: $fs-xxs !important;
    line-height: 1rem !important;
    padding: 2.5px 8px !important;
    height: 20px;
}

.tag-sm{
    font-size: $fs-xxs !important;
    line-height: 1rem !important;
    padding:6px 8px;
}

.tag-md{
    font-size: $fs-xs !important;
    line-height: 1.125rem !important;
    padding: 6px 8px !important;
    height: 30px !important;
}

.tag-lg{
    font-size: $fs-sm !important;
    line-height: 1.3rem !important;
    padding: 9.5px 12px !important;
    height: 40px !important;
}
.iconStyle {
    display: flex;
    justify-content: center;
    align-items: center;
}