@import "@styles/variables";

.cardWrapper {
  border: 3px solid $neutral-30;
  border-top: 0;
  .actionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
  }
  .mixedPalletLink {
    cursor: pointer;
    max-width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      color: inherit;
    }
  }
  .mixedPalletImg {
    display: none;
  }
  .mixedPalletItemInfo {
    a:hover {
      color: inherit;
    }
  }
  .mixedPalletItemsWrapper {
    border-top: 2px solid $neutral-30;
    .mixedPalletItems {
      border: 2px solid $neutral-30;
      border-top: none;
    }
  }

  .card {
    display: flex;
    align-items: center;
    .mixedPalletTitle {
      flex-grow: 1;
    }
    .iconWrapper {
      border: 0;
      background: transparent;
      cursor: pointer;
      padding: 0;
      div {
        display: flex;
      }
    }

    &__info {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }
    &__details {
      display: flex;
      flex-direction: column;
      gap: 12px;
      a:hover{
        color: $oxford-blue;
      }
      @media (max-width: $screen-width-md) {
        flex-grow: 1;
      }
      &__title {
        text-transform: uppercase;
      }
      &__priceSection {
        display: flex;
        .divider {
          height: auto;
          margin: 0 6px;
        }
        &__label {
          color: $neutral-70;
        }
      }
    }
  }
}
