@import "@styles/variables";

.dataLoader{
  align-items: center;
  justify-content: center;
  height: 100%;
}
.mainSection {
  display: flex;
  justify-content: space-between;
  .haggleWrapper{
    border-top: 1px solid rgba(137, 137, 137, 0.3);
  }
  .hasHaggle {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
  }
  .haggleText {
    display: flex;
    align-items: center;
  }
  .link {
    height: 100%;
    line-height: 14px;
  }
  .addToPalletBttn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    gap: 5px;
  }
  .fontItalic {
    font-style: italic;
  }
  .reportBtn {
    font-size: 14px;
    height: 20px;
  }
  .productDetailsSection {
    border: 3px solid $neutral-30;
    .headerButtons {
      flex-direction: row-reverse;
      margin-right: 16px;
      .headerButtonsWrapper {
        display: flex;
        gap: 8px;
        align-items: center;
        button {
          width: 44px;
          height: 44px;
          border: 1px solid $neutral-30;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $white-color;
          &:hover {
            background-color: $oxford-blue;
          }
        }
        .wishlistedItem {
          background-color: $oxford-blue;
          :global {
            .ant-btn-icon {
              margin-inline-end: 0;
            }
          }
        }
      }
    }

    .haggleLink {
      align-self: center;
      :global {
        .ant-image {
          padding: 3px;
        }
      }
    }

    .iconButtonWrapper {
      :global {
        .ant-btn-icon {
          margin-inline-end: 0;
        }
      }
      &:hover {
        .iconStyle {
          svg {
            fill: #fff;
          }
        }
      }
    }
    .imageContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .productMainImage {
        min-height: 300px;
        .productVideoWrapper {
          height: 100%;
          width: 100%;
          .productVideo {
            width: 100%;
          }
        }

        .CarouselVideo {
          min-height: 350px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }

        :global {
          .ant-carousel {
            .slick-slider {
              .slick-list {
                .slick-track {
                  .slick-slide {
                    display: flex;
                    justify-content: center;

                    > div {
                      width: 100%;
                    }
                  }
                }
              }
              .slick-arrow {
                font-size: 0px;
                background-color: $primary-light !important;
                padding-top: 5px;
                top: 50% !important;
                z-index: 1;
                width: 35px !important;
                height: 35px !important;
              }
              .slick-next {
                right: -5px !important;
              }
              .slick-arrow::before {
                opacity: unset;
                font-size: $fs-h5;
                color: $black-color;
              }
              .slick-prev {
                left: -5px !important;
              }
              .slick-disabled {
                cursor: no-drop;
              }
              .slick-disabled::before {
                opacity: 0.25;
              }
            }
          }
        }
        img {
          max-width: 100%;
          height: auto;
          object-fit: contain;
          padding-left: 30px;
          padding-right: 22px;
          margin: auto;
        }
      }
      .coolSchoolContainer {
        width: fit-content !important;
        height: 357px;
        background-color: $footer-bg-right;
        display: flex !important;
        justify-content: center;
        align-items: center;
        text-align: center;
        span {
          color: $neutral-50;
        }
      }
      .thumpImagesCarousel {
        width: 100%;
        margin: 0 auto;
        :global {
          .ant-carousel {
            .slick-slider {
              .slick-list {
                .slick-track {
                  justify-content: center;
                  .slick-slide:last-child {
                    margin-right: 0;
                  }
                  .slick-slide {
                    margin-right: 8px;
                  }
                }
              }
              .slick-arrow {
                font-size: 0px;
                background-color: $primary-light !important;
                padding-top: 5px;
                top: 50% !important;
                transform: translateY(-50%);
                z-index: 1;
                width: 30px !important;
                height: 30px !important;
              }
              .slick-next {
                right: -5px !important;
              }
              .slick-arrow::before {
                opacity: unset;
                font-size: $fs-h5;
                color: $black-color;
              }
              .slick-prev {
                left: -5px !important;
              }
              .slick-disabled {
                cursor: no-drop;
              }
              .slick-disabled::before {
                opacity: 0.25;
              }
            }
          }
        }

        .productThumpItem {
          cursor: pointer;
          img {
            width: 84px;

            object-fit: contain;
            @media (max-width: $screen-width-lg) {
              width: 68px;
            }
            @media (max-width: $screen-width-sm) {
              width: 84px;
            }
            @media (max-width: $screen-width-xs) {
              width: 48px;
            }
          }
          .playIconWrapper {
            width: 84px;
            height: 84px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: $screen-width-lg) {
              width: 68px;
              height: 68px;
            }
            @media (max-width: $screen-width-sm) {
              width: 84px;
              height: 84px;
            }
            @media (max-width: $screen-width-xs) {
              width: 48px;
              height: 48px;
            }
            .iconWrapper {
              border-radius: 50%;
              width: 34px;
              height: 34px;
              background-color: rgba(74, 144, 226, 0.1);
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          border: 1px solid $neutral-50;
          display: flex !important;
          align-items: center;
          justify-content: center;
        }
        .productActiveThumb {
          border: 1px solid $primary-color;
        }
      }
    }
    .detailsContainer {
      .productDetailsUpperSection {
        .productTags {
          .productTag {
            padding: 4px 8px;
          }
        }
        border-bottom: 1px solid $neutral-50;
        .borderRight {
          border-right: 1px solid $neutral-50;
        }
        .leadTimeButton {
          border: none;
          padding: 0;
          height: 24px;
          &:hover {
            background-color: unset;
          }
        }
      }
      .productDetailsBottomSection {
        .productDetailsBtn {
          display: flex;
          align-items: center;
          &:hover {
            .lockIcon {
              svg {
                fill: $white-color;
              }
            }
          }
        }
        .reportBtn {
          height: auto;
          font-size: 10px;
        }
        .productActionSection {
          button {
            height: 48px;
          }
        }
      }
    }
  }

  .right-section {
    .promoTag + .productPricesSection {
      padding-top: 16px;
    }

    .flagContainer {
      display: flex;
      justify-content: flex-end;
      filter: drop-shadow(0 4px 1px rgba(0, 0, 0, 0.1));
      width: 100%;
      .boxShadow {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .flagBlock {
          background-color: $red-color1;
          -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0, 7% 50%);
          clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0, 7% 50%);
          filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.1));
          text-align: center;
          display: flex;
          justify-content: center;
          padding: 15px 10px 15px 12px;
          max-width: 255px;
          color: #fff;
          width: calc(100% - 22px);
        }
        .fullWidth {
          max-width: 100%;
        }
      }
    }

    .signUpContainer {
      button,
      a {
        width: 95px;
        height: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .deliveryDetails {
      border-bottom: 1px solid rgba(181, 215, 253, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .deliveryDetails:last-child {
      border-bottom: 0;
    }
    .rightAlign {
      text-align: right;
    }
    .leftAlign {
      text-align: left;
    }
    .quantityContainer {
      border-bottom: 1px solid rgba(137, 137, 137, 0.3);
      border-top: 1px solid rgba(137, 137, 137, 0.3);
      .quantityDetails {
        display: flex;
        align-items: center;
      }
      .addMore{
        @media(min-width: ($screen-width-md + 1)){
            display: none;
        }
      }
    }
    .totalContainer {
      display: flex;
      align-items: center;
    }
    .addToCartContainer {
      .addToCart {
        height: 48px;
        width: 100%;

        svg {
          margin-right: 5px;
        }
      }
    }
    .letUsKnow {
      text-align: center;
      button {
        height: auto;
      }
    }
    .mobilePriceSummerySection {
      display: none;
    }
  }
}
.product-unavailable {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px 10px;
}
.ant-collapse {
  background-color: $footer-bg-right;

  svg {
    vertical-align: middle;
    cursor: pointer;
  }
}

.ant-collapse {
  &.active {
    background-color: $collapse-active;
  }
}

.ant-collapse-item {
  .ant-collapse-header-text {
    font-size: $fs-h5;
    font-weight: $bold-font-weight;
    line-height: 1.5;
    color: $oxford-blue;
    flex-grow: 1 !important;
  }
}

.ant-collapse-item-disabled {
  .ant-collapse-header {
    cursor: pointer !important;
  }

  .ant-collapse-header-text {
    color: $neutral-70;
  }
  svg {
    fill: $neutral-70;
  }
}
.ant-tooltip-inner {
  font-size: 12px;
}
@media (max-width: $screen-width-md) {
  .mainSection {
    .productDetailsSection {
      margin-bottom: 24px;
      padding: 24px 13px 26px 13px;
      .headerButtons {
        margin-top: 0;
        margin-right: 0;
        .headerButtonsWrapper {
          display: flex;
          button {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            div {
              display: flex;
              justify-content: center;
              text-align: center;
            }
          }
        }
      }
      .imageContainer {
        padding: 0 22px;
        justify-content: center;
        margin-bottom: 40px;
        .productMainImage {
          img {
            max-height: 300px;
            padding: 0;
            margin: auto;
          }
          :global {
            .ant-carousel {
              .slick-slider {
                .slick-list {
                  .slick-track {
                    .slick-slide {
                      margin-right: 0;
                    }
                  }
                }
                .slick-next {
                  right: 0 !important;
                }
                .slick-prev {
                  left: 0 !important;
                }
              }
            }
          }
          text-align: center;
          //margin-bottom: 24px;
        }
        .thumpImagesCarousel {
          :global {
            .ant-carousel {
              .slick-slider {
                .slick-list {
                  .slick-track {
                    .slick-slide {
                      margin-right: 8px;
                    }
                  }
                }
                .slick-arrow {
                  display: none !important;
                }
              }
            }
          }
        }
      }
      .detailsContainer {
        padding: 0;
        margin-bottom: 0;
        .productDetailsUpperSection {
          .productTags {
            margin-bottom: 20px;
            .productTag {
              margin-right: 4px;
              margin-top: 8px;
              padding-left: 4px !important;
              span {
                font-size: 12px;
              }
            }
          }
          .productSizesWrapper {
            span {
              white-space: nowrap;
            }
          }
        }
        .productDetailsBottomSection {
          .productInformation {
            margin-top: 12px;
            span {
              font-size: 10px;
            }
          }
          .productDetailsText {
            margin-top: 8px;
            margin-bottom: 0;
          }
          .bottomSectionTitle {
            font-size: 16px;
          }
          .productActionSection {
            margin-bottom: 20px;
            button {
              font-size: 12px;
              font-weight: 500;
              line-height: 15px;
              span {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .downloadSpecSheetBtn {
              padding-left: 14px;
              padding-right: 13px;
            }
            .compareBtn {
              padding-left: 20px;
              padding-right: 19px;
            }
          }
        }
      }
    }
    .haggleLink {
      :global {
        .ant-image {
          width: 32px !important;
        }
      }
    }
    .right-section {
      padding-left: 0;
      .productPricesSection {
        padding-top: 0;
        .fontItalic {
          font-style: italic;
        }
        .deliveredPriceTitle {
          font-size: 24px;
          font-weight: 800;
        }

        .pricesDetails {
          .deliveryDetails {
            padding: 16px 0;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
        border-bottom: 1px solid rgba(137, 137, 137, 0.3);
      }

      .rightAlign {
        text-align: right;
      }
      .quantityContainer {
        border-bottom: 0;
        flex-direction: column;
        .leftAlign,
        .rightAlign {
          display: none;
        }
      }
      .totalContainer {
        display: none;
      }
      .addToCartContainer {
        display: none;
      }
      .letUsKnow {
        text-align: center;
      }
      .mobilePriceSummerySection {
        display: block;
        .mobileTotalContaner {
          display: flex;
          align-items: center;
        }
        .MobileAddToCartSection {
          display: flex;
          justify-content: space-between;
          .addToCart {
            height: 48px;
            width: 212px;
          }
        }
      }
    }
  }
  .ant-collapse-item {
    .ant-collapse-header-text {
      font-size: $fs-sm;
    }
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
  left: 10px;
  z-index: 2;
  color: white;
  font-size: 20px;
  height: 30px;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  right: 10px;
  z-index: 2;
  color: white;
  font-size: 20px;
  height: 30px;
}

.addToPallet {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;

  svg {
    margin-right: 5px;
  }
}
.videoPlayModal {
  :global {
    .ant-modal-content {
      .ant-modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      padding: 0;
      background: transparent;
      box-shadow: unset;
      .ant-modal-close {
        position: absolute;
        top: -22px;
        right: 0;
        @media (max-width: $screen-width-sm) {
          top: 20px;
          right: -25px;
        }
      }
    }
  }
  @media (max-width: $screen-width-md) {
    width: fit-content !important;
    height: fit-content !important;
  }
}

@media (max-width: $screen-width-sm) {
  .mainSection {
    .productDetailsSection {
      .imageContainer {
        .productMainImage {
          img {
            padding: 0 6px;
            margin: auto;
          }
          :global {
            .ant-carousel {
              .slick-slider {
                .slick-next {
                  right: -33px !important;
                }
                .slick-prev {
                  left: -33px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
.baseValue {
  text-decoration: line-through;
}
.priceValueItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 1;
}
