@import "@styles/variables";

.actionButtonsContainer {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
}

.errorMessage{
  color: $red-color1;
}
