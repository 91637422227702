@import "@styles/variables";

.input-wrapper {
  display: inline-block !important;
  padding: 0 !important;
  padding-right: 16px !important;
  border: 1px solid rgba(64, 75, 96, 0.2) !important;
  &.input-prefix {
    padding-right: 0 !important;
    padding-left: 12px !important;
  }

  .ant-input-group {
    height: 100%;
    display: inline-block;
    width: 100%;
    .ant-input-affix-wrapper {
      display: flex !important;
      align-items: center !important;
      height: 100%;
    }
  }

  &:focus-within {
    border: 1px solid #52a2f9 !important;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1) !important;
    border-right-width: 1px !important;
    outline: 0 !important;

    svg {
      path {
        fill: #076ddc;
      }
    }
  }

  .ant-input {
    height: 100% !important;
    padding: 16px 12px 14px !important;
    font-size: $fs-sm !important;
    border: none !important;
    @media (max-width: $screen-width-xs) {
      font-size: $fs-base !important;
    }

    &:focus {
      outline: 0 !important;
    }
  }
  .ant-input-prefix {
    display: flex;
    margin-inline-end:0;
  }
  .ant-input-suffix {
    display: inline;
    margin-inline-start:0;
    padding: 0px 4px;
    width: min-content;

    svg {
      vertical-align: middle;
    }
  }
}

.ant-input-affix-wrapper {
  display: flex !important;
  align-items: center !important;

  &.ant-input-affix-wrapper-readonly {
    border: 0 !important;
    padding: 0 !important;
    align-items: center !important;
  }

  &::before {
    content: none !important;
  }

  &:focus-within {
    outline: 0;
    box-shadow: unset;
  }
}

.read-only {
  position: relative;
  .ant-input-group-addon {
    position: absolute;
    left: 5px;
    top: -8px;
    background: $white-color;
    padding: 0 7px;
    color: $neutral-50;
    font-weight: $semi-font-weight;
    font-size: $fs-xs;
    line-height: 1rem;
    z-index: 1;
    width: auto;
    border: 0;
  }
}

.label-up {
  position: relative;
  padding-right: 0 !important;
  border-radius: 8px;
  width: 100%;
  .ant-input-group-addon {
    font-weight: 500;
    border: 0;
    background: inherit;
    position: absolute;
    opacity: 60%;
    color: $oxford-blue;
    top: 0;
    left: 0;
    font-size: 10px;
    top: 4px;
    padding: 0 11px;
    z-index: 1;
  }
}

.read-only {
  position: relative;
  .ant-input-group-addon {
    position: absolute;
    left: 5px;
    top: -8px;
    background: $white-color;
    padding: 0 7px;
    color: $neutral-50;
    font-weight: $semi-font-weight;
    font-size: $fs-xs;
    line-height: 1rem;
    z-index: 1;
    width: auto;
    border: 0;
  }

  .ant-input {
    border: 0;

    &:focus {
      outline: 0 !important;
      border: 0 !important;
      box-shadow: unset !important;
    }
  }

  &:focus-within {
    outline: 0 !important;
    box-shadow: none !important;
    border-color: rgba(64, 75, 96, 0.2) !important;

    svg {
      path {
        fill: initial !important;
      }
    }
  }
}

.btn-success {
  border: 1px solid $tertiary-green !important;

  &:focus-within {
    border: 1px solid $tertiary-green !important;

    svg {
      path {
        fill: initial;
      }
    }
  }
}

.btn-error {
  border: 1px solid #f26767 !important;

  &:focus-within {
    border: 1px solid #f26767 !important;

    svg {
      path {
        fill: initial;
      }
    }
  }
}

.text-danger {
  color: $tertiary-pink;
  font-size: $fs-xxs;
  font-weight: $regular-font-weight;
  line-height: 1rem;
  padding-left: 12px;
  padding-top: 3px;
}
.manageAccountField {
  height: 48px;
  background-color: $white-color;
  width: 100%;
}
