$max: 80;
$offset: 2;
$unit: 'px';

@mixin generate-spacing($className, $firstProperty, $secondProperty: "") {
  $i: 0;
  @while $i <= $max {
    $classNameSuffix: $i;
   
    @if($i > 0){
        $classNameSuffix: $i / 2;
    }

    #{$className + $classNameSuffix} { 
      #{$firstProperty}: #{$i + $unit};
      @if $secondProperty != ""{
        #{$secondProperty}: #{$i + $unit};
      }
    }
    $i: $i + $offset;
  }
}

// Margins
@include generate-spacing('.m-', 'margin');
@include generate-spacing('.mt-', 'margin-top');
@include generate-spacing('.me-', 'margin-right');
@include generate-spacing('.mb-', 'margin-bottom');
@include generate-spacing('.ms-', 'margin-left');
@include generate-spacing('.mx-', 'margin-left', 'margin-right');
@include generate-spacing('.my-', 'margin-top', 'margin-bottom');


// Paddings
@include generate-spacing('.p-', 'padding');
@include generate-spacing('.pt-', 'padding-top');
@include generate-spacing('.pe-', 'padding-right');
@include generate-spacing('.pb-', 'padding-bottom');
@include generate-spacing('.ps-', 'padding-left');
@include generate-spacing('.px-', 'padding-left', 'padding-right');
@include generate-spacing('.py-', 'padding-top', 'padding-bottom');