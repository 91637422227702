@import "@styles/variables";

@mixin generate-colors($className, $property) {
  $colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "white": $white-color,
    "black": $black-color,
    "success": $success,
    "error": $error,
    "oxfordBlue": $oxford-blue,
    "grey": $neutral-20,
    "primaryLighter": $primary-lighter,
    "secondaryLighter": $secondary-lighter,
    "discountColor": $discount-color,
    "transparent": $transparent,
    "darkGray": $neutral-70,
    "tertiaryPink": $tertiary-pink,
    "neutralGray": $neutral-50,
    "warning":$secondary-yellow,
    "platinum": $neutral-30,
  );

  @each $colorName, $colorValue in $colors {
    #{$className + $colorName} {
      #{$property}: #{$colorValue};
    }
  }
}

// Background Colors
@include generate-colors(".bg-", "background-color");
// Text Colors
@include generate-colors(".text-", "color");
