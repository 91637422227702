@import "@styles/variables";

.colWrapper {
  flex: 0 0 27%;
  max-width: 267px;
  margin-right: 82px;
  .sidebarWrapper {
    h2 {
      @media (max-width: $screen-width-lg) {
        padding-left: 24px;
      }
    }
    .sideBar {
      background: inherit;
    }
    .menu {
      background: inherit;
      li {
        margin: 0;
        width: auto;
        padding: 20px 25px;
        height: 24px;
        box-sizing: content-box;
        border-radius: 0;
        border-bottom: 1px solid rgba(64, 75, 96, 0.4);

        div {
          height: 100%;
        }
        span {
          margin-left: 10px;
          font-size: $fs-base;
          font-weight: $semi-font-weight;
        }
      }
      :global {
        .ant-menu-item-selected {
          background-color: $oxford-blue !important;
          color: $white-color !important;
          svg {
            fill: $white-color !important;
          }
        }
      }
    }
  }
  @media (max-width: $screen-width-md) {
    background: $white-color;
    flex: unset;
    h2 {
      display: none;
    }
  }
}
.mobileViewMenu {
  display: none;
}

@media (max-width: $screen-width-xl) {
  .colWrapper {
    max-width: 240px;
    margin-right: 40px;
  }
}
@media (max-width: $screen-width-lg) {
  .colWrapper {
    margin-right: 30px;
  }
}
@media (max-width: $screen-width-md) {
  .colWrapper {
    display: none;
  }
  .mobileViewMenu {
    display: block;
    padding: 40px 0 0;
    button {
      display: flex;
      border-bottom: 0;
      span {
        font-size: 20px;
        font-weight: 600;
      }
    }
    :global {
      .ant-menu-item-selected {
        background-color: unset !important;
      }
    }
    &.overflow {
      overflow-y: scroll;
      height: calc(100vh - 128px);
    }
  }
  .accountPage {
    position: fixed;
    width: 100vw !important;
    height: 100vh;
    z-index: 101;
    padding: 0;

    .mobileViewMenu {
      padding-top: 0;
      padding-bottom: 250px;

      h2 {
        margin: 40px 16px 16px;
      }
      :global {
        .ant-menu {
          padding: 0 16px;
          .ant-menu-item {
            height: auto;
            padding: 24px 0;
            padding-left: 0 !important;
            line-height: 100%;
            margin: 0;
            width: 100%;
            border-bottom: 1px solid rgba(5, 5, 5, 0.06);
            border-radius: 0;
            .ant-menu-title-content {
              padding-left: 10px;
              line-height: initial;
              &:after {
                content: "";
                display: inline-block;
                height: 14px;
                float: right;
                margin-right: 10px;
                width: 14px;
                position: relative;
                background: transparent
                  url("https://22338452.fs1.hubspotusercontent-na1.net/hubfs/22338452/icons_storefront/MicrosoftTeams-image%20(2).png")
                  no-repeat;
              }
            }

            div {
              height: 24px;
            }
            &:last-child {
              border-bottom: unset;
              .ant-menu-title-content {
                &::after {
                  background: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}
.layoutBack {
  position: relative;
  background: linear-gradient(
    90deg,
    rgb(213, 230, 249, 0.4) 50%,
    $white-color 50%
  );
  @media (min-width: $screen-width-md) {
    min-height: 1059px;
  }
}
@media (max-width: $screen-width-md) {
  .layoutBack {
    background: $white-color;
  }
  .innerLayout {
    padding-left: 0;
  }
}
.innerLayout {
  :global {
    .site-layout-background {
      height: 100%;
    }
  }
}
