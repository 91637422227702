@import "@styles/variables";

.shoppingListItem {
  background: rgb(213, 230, 249, 0.2);
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    &__icons {
      display: flex;
      gap: 8px;
      button {
        border: 0;
        background: transparent;
        height: fit-content;
        cursor: pointer;
      }
      div {
        border: 1px solid $neutral-30;
        background: $footer-bg-right;
        border-radius: 50%;
        max-width: unset !important;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .customEditInput {
      padding: 8px 0 8px 4px;
      margin: 0;
    }
  }
  .actionsContainer {
    display: flex;
    flex-direction: column;
    .tagContainer {
      text-align: right;
    }
  }

  .btnWrapper {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    width: 100%;

    button,
    .viewBtn {
      padding: 8px 20px;
      width: 50%;
      @media (max-width: $screen-width-md) {
        padding: 8px 12px;
      }
    }
    .disabledView {
      cursor: not-allowed;
      color: $neutral-50;
      border: #404b6033;
      background-color: $neutral-30;
    }
    .disabledView:hover {
      color: $neutral-50;
      border: #404b6033;
      background-color: $neutral-30;
    }
  }
  &.dahboardstyle {
    background-color: $white-color;
    border: 2px solid $neutral-30;
    border-radius: 0;
  }
  .shoppingListName {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  .shoppingListDetails {
    max-width: calc(100% - 98px);
  }
}
