@import "@styles/variables";

.hubArticles {
  margin-bottom: 0 !important;
  @media (max-width: $screen-width-md) {
    padding-bottom: 60px !important;
    .titleContainer {
      margin-bottom: 12px;
    }
  }
}
.subTitle{
  line-height: 24px;
  @media (max-width: $screen-width-xs){
    max-width: 254px;
  }
}
.storeCarousel{
  margin-bottom: 0 !important;
  padding-top: 60px !important;
  padding-bottom: 60px !important;
  @media (max-width: $screen-width-md) {
    padding-bottom: 0px !important;
  }
}
.mixMatchCarousel {
  @media (max-width: $screen-width-xs) {
  padding-inline: 0px !important;
  padding-block-end: 0px !important;
  }

  @media (max-width: $screen-width-md) {
    margin-top: 10px;
    padding-inline: 0 !important;
    :global {
      .slick-slide {
          margin-inline-end: 0px !important;
      }
    }
  }
}

.carouselContainer {
  padding-top:70px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: $screen-width-md) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  :global {
    .ant-carousel {
      .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              height: auto;
              @media (max-width: $screen-width-xs) {
                margin: 0;
              }
            }
            .slick-slide > div {
              height: 100%;
            }
          }
        }
      }
    }
  }

  &.feedbackContainer {
    @media (max-width: $screen-width-sm) {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

.recentlyViewed {
  padding-right: 0;
  padding-left: 0;
  .titleContainer {
    .carouselTitle {
      font-size: $fs-h5;
      font-weight: $bold-font-weight;
      line-height: 24px;
      button {
        font-weight: $bold-font-weight;
      }
    }
  }
  :global {
    .productCard {
      width: 348px;
    }
    .slick-dots {
      display: none !important;
    }
  }
}
.feedbackContainer {
  .titleContainer {
    margin-bottom: 12px;
  }
}
.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 20px;
  width: fit-content;

  @media (max-width: $screen-width-sm) {
      margin-bottom: 10px;
  }
}

.carouselTitle {
  font-weight: $bolder-font-weight;
  font-size: 40px;
  line-height: 48px;
  margin: 0;
  @media (max-width: $screen-width-sm) {
    font-size: $fs-h4;
    padding-right: 94px;
    line-height: 28px;
  }
}

.viewAllText {
  font-weight: $bold-font-weight;
  font-size: $fs-sm;
  line-height: 1.125rem;
  vertical-align: middle;
  margin-left: 12px;

  button {
    font-size: $fs-sm;
    font-weight: 700;
    height: 21px;
  }

  @media (max-width: $screen-width-sm) {
    margin-left: 0;
    font-size: $fs-xs;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    &.haggleView{
      position: unset;
      margin-left: 6px;
    }
    button {
      height: 20px;
      font-size: $fs-xs;
    }
  }
}

.feedbackContainer{
  :global {
    .slick-slider{
      .slick-list{
        max-height: unset;
      }
    }
  }
}

.searchPageCarousel {
  .carouselTitle {
    padding-right: 100px;
    color: $black-color;
  }
}