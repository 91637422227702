@import "@styles/variables";

.articleCard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 346px;
  height: auto;

  .bookmarkBtn {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: $oxford-blue;
    opacity: 0.5;
    backdrop-filter: blur(10px);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
  }

  .articleContent {
    border: 3px solid #e7e7e7;
    border-top: 0px;
    background-color: $white-color;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;

    .articleTime {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 0.5rem;

      span {
        text-transform: uppercase;
        color: $oxford-blue;
        font-style: normal;
        font-weight: $bold-font-weight;
        font-size: $fs-xs;
      }
    }

    .titleText {
      font-style: normal;
      font-weight: $medium-font-weight;
      font-size: $fs-h5;
      line-height: $fs-h5;
      color: $oxford-blue;
    }
    .categoriesContainer {
      display: flex;
      flex-direction: row;
      gap: 4px;
      flex-wrap: wrap;
    }
    .categoryItem {
      background: $primary-light;
      border: 1px solid $jeans-blue;
      padding: 6px 4px;
      text-transform: uppercase;
      color: $oxford-blue;
      font-style: normal;
      font-weight: $bold-font-weight;
      font-size: $fs-xs;
    }

    .readMoreText {
      font-weight: $bold-font-weight;
      font-size: $fs-sm;
      line-height: 1.125rem;
      vertical-align: middle;
      margin-top: 1rem;
      width: 25%;
      button {
        height: 24px;
      }

      span {
        font-size: $fs-sm;
      }
    }
  }
}
