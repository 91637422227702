@import "@styles/variables";

.topContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 50px;
  .title {
    margin-top: 6px;
    @media (max-width: $screen-width-sm) {
      width: 50% !important;
    }
    h3 {
      width: 540px;
      height: 25px !important;
      @media (max-width: $screen-width-sm) {
        width: 100% !important;
      }
    }
  }

  .rightArrow {
    width: 80px;
    h3 {
      width: 80px !important;
      height: 40px !important;
    }
  }
}
.boxContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: $screen-width-sm) {
    justify-content: center;
  }
}
.box {
  width: 343px;
  h3 {
    height: 400px !important;
  }
}
