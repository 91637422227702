@import "@styles/variables";
.miniCartLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
}
.cartWrapper {
  .quantity {
    position: absolute;
    top: -10px;
    right: -5px;
    border-radius: 50%;
    background: $tertiary-pink;
    border: 2px solid #f5f5f5;
    color: $white-color;
    line-height: $fs-base;
    min-width: 20px;
    min-height: 20px;
    max-width: 27px;
    max-height: 27px;
    padding: 0 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vendorsItem{
    border-top: 3px solid #e7e7e7;;
  }
  @media (max-width: $screen-width-sm) {
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track {
    background-color: $neutral-30;
    border-radius: 50px;
  }
  .cart {
    &:hover {
      opacity: 0.7;
    }
    div {
      display: flex;
      align-items: center;
    }
  }
  button.cart {
    border-bottom: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .miniCart {
    position: fixed;
    top: 0;
    right: 0;
    height: -webkit-fill-available;
    z-index: 999;
    width: 590px;
    box-shadow: -30px 24px 64px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    @media (max-width: $screen-width-sm) {
      position: absolute;
      top: 100%;
      right: 0;
      max-height: 100vh;
      height: 100vh;
      z-index: 999;
      padding: 24px 0;
      &__items {
        padding-bottom: 200px;
      }
      &__header,
      &__items,
      label,
      &__footer__checkoutBtnWrapper {
        padding-left: 16px;
        padding-right: 16px;
      }
      h2 {
        font-size: $fs-h4;
      }
    }
    @media (max-width: $screen-width-xs) {
      width: 100%;
    }
    .closeIcon {
      background: $white-color;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__items {
      max-height: calc(100vh - 393px);
      overflow-y: auto;
    }
    &__footer {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;
      @media (max-width: $screen-width-sm) {
        position: fixed;
        bottom: 0;
        right: 0;
        padding-bottom: 24px;
        margin: auto;
        width: 100%;
        max-width: 590px;
      }
      &__checkoutBtnWrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        @media (max-width: $screen-width-sm) {
          border-top: 1px solid $neutral-30;
          box-shadow: 0 -16px 34px rgb(0 0 0 / 8%);
        }
      }
      label {
        display: flex;
        justify-content: space-between;
      }
      &__order {
        border-top: 1px solid rgba(181, 215, 253, 0.5);
        @media (max-width: $screen-width-sm) {
          margin-bottom: 32px;
        }
      }
      &__checkoutBtn {
        height: auto;
      }
    }
  }
}

.modal {
  a:hover{
    color: $jeans-blue;
  }
  :global {
    .ant-modal-content {
      padding: 2rem;
      color: $neutral-70;

      .ant-modal-title {
        font-size: $fs-h4;
        padding-bottom: 20px;
      }

      .ant-modal-body {
        div:nth-child(2) {
          display: flex;
          justify-content: space-between;
          column-gap: 1rem;

          div {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;
            button {
              height: auto;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
