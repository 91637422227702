@import "@styles/variables";

.checkboxWrapper, .labelWrapper{
  display: flex;
  align-items: center;
  gap: 8px;
}
.labelWrapper{
  cursor: pointer;
  div{
    display: flex;
  }
}
.Checkbox {
  margin-inline-start: 0px !important;
  display: flex;
  span{
    display: flex;
    gap: 8px;
  }
  input {
    margin: 0 8px 0 0 !important;
  }

  > span:nth-child(2) {
    font-weight: $medium-font-weight;
    line-height: 1.5rem;
    font-size: $fs-base;
  }
}
