@import "@styles/variables";

.itemWrapper {
  background-color: $secondary-lighter;
  display: flex;
  flex-wrap: nowrap; 
  align-items: center;
  justify-content: center;
  gap: 1rem; 

  .wrapperTitleLogo{
    max-width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    justify-content: space-between;
    gap: 1px; 
    
  }
  

  @media (max-width: $screen-width-mobile-md) {
    flex-wrap: wrap; 
    justify-content: center; 
  }
  
  .iconWrapper {
    flex-shrink: 0; 
  }

  .title {
    font-size: 1.5rem;
    font-weight: 800;
    margin: 0;
    flex: 1 1 auto; 
    min-width: 30px; 
    word-break: break-word;
    white-space: normal; 
    text-align: left; 

    @media (max-width: $screen-width-mobile-lg) {
      width: 100%; 
      margin-bottom: 10px;
      text-align: center; 
    }
  }

  .btnWrapper {
    margin: 0;
    padding: 8px 16px;
    width: 160px; 
    height: 48px;
    flex-shrink: 0; 
    white-space: nowrap; 

    @media (max-width: $screen-width-mobile-lg) {
      margin: 0 auto; 
    }
  }
}

.itemWrapperGuestUser {
  background-color: $secondary-lighter;
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center; 
  gap: 1rem;
  padding: 16px; 

  .wrapperTitleLogo {
      display: flex;
      min-width: 20px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1px; 

      @media (max-width: 1600px) {
          gap: 4px; 
      }
  }

  .title {
      font-size: 16px; 
      font-weight: 800;
      margin: 0;
      flex-grow: 1; 
      min-width: 18px; 
      text-align: left;

      @media (max-width: $screen-width-mobile-lg) {
          text-align: center; 
      }
  }

  .btnWrapper {
    flex-shrink: 0; 
    padding: 8px 12px;
    max-width: fit-content;
    min-width: auto; 
    height: 48px;
    white-space: nowrap; 

    @media (max-width: 1600px) {
        font-size: 0.9rem; 
        padding: 6px 10px; 
    }

    @media (max-width: 1290px) {
        width: 100%; 
       
    }
}

  .iconWrapper {
      flex-shrink: 0; 
      width: 54px;
      height: 54px;

      @media (max-width: 1600px) {
          width: 40px;
          height: 40px;
      }

      @media (max-width: 1290px) {
          width: 36px; 
          height: 36px;
      }
  }

  @media (max-width: 1600px) and (min-width: 1241px) {
      flex-wrap: nowrap; 
      gap: 0.5rem; 
      padding: 10px;
  }

  @media (max-width: 1430px) {
      flex-direction: column; 
      align-items: center; 
      .title {
          text-align: center;
      }
  }
  @media (max-width: $screen-width-md) {
    flex-direction: row !important; 
    align-items: center; 
    justify-content: center;
    flex-wrap: wrap;
    .title {
        text-align: center; 
    }
}
}

.promoQ {
  padding: 24px 0;
  background: $white-color;

  @media (max-width: $screen-width-mobile-lg) {
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    max-width: 100%;

    .itemWrapper {
      margin: 0 16px;
    }
  }
}

.promoQ, .StorePromoQ {
  padding: 0;

  @media (max-width: $screen-width-md) {
    padding: 24px 0;
    padding-bottom: 0;
    background: $white-color;
  }

  .itemWrapper {
    padding: 12px 10px;
    flex-direction: row; 
    flex-wrap: wrap; 
    align-items: center; 
    justify-content: center;
    gap: 10px; 

    @media (max-width: $screen-width-mobile-md) {
      flex-wrap: wrap; 
      justify-content: center;
      
    }
    .wrapperTitleLogo{
      max-width: max-content;
      display: flex;
      flex-direction: row;
      align-items: center; 
      justify-content: space-between; 
      
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $fs-base;
      text-align: left; 
      margin: 0;
      max-width: 100px;
      min-width: 40px;

      @media (max-width: $screen-width-mobile-lg) {
        width: 100%; 
        margin-bottom: 10px; 
        text-align: center; 
      }
    }

    .btnWrapper {
      max-width: max-content;
      height: 48px; 
      margin: 0;
      padding: 8px 14px;

      @media (max-width: $screen-width-md) {
        width: 160px; 
        height: 48px; 
      }
    }
  }

  .itemWrapperGuestUser {
    background-color: $secondary-lighter;
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: center; 
    gap: 1rem;
    padding: 16px;
  
    .wrapperTitleLogo {
      display: flex;
      align-items: center;
      gap: 8px;
      @media (min-width: 1339px) {
        gap: 1px; 
      }
    }
  
    .title {
      font-size: 16px; 
      font-weight: 800;
      margin: 0;
      flex-grow: 1; 
      text-align: left; 
      min-width: 30px; 
      word-wrap: break-word; 
  
      @media (max-width: 1339px) {
        text-align: center; 
      }
    }
  
    .btnWrapper {
      flex-shrink: 0; 
      padding: 8px 12px;
      width: auto; 
      height: 48px;
      white-space: nowrap; 
  
      @media (max-width: 1290px) {
        width: 100%; 
        
      }
    }
  
    .iconWrapper {
      flex-shrink: 0; 
      width: 54px;
      height: 54px;
  
      @media (max-width: 1339px) {
        width: 40px;
        height: 40px; 
      }
  
      @media (max-width: 1290px) {
        width: 36px;
        height: 36px; 
      }
    }
  
  
    @media (max-width: 1600px) and (min-width: 1241px) {
      gap: 0.5rem; 
      padding: 10px; 
    }
  
   
    @media (max-width: 1339px) {
      flex-wrap: wrap; 
      justify-content: center; 
      text-align: center; 
      padding: 12px; 
  
      .title {
        text-align: center; 
      }
  
      
    }
  
  
    @media (max-width: $screen-width-md) {
      flex-direction: column; 
      align-items: center; 
      text-align: center;
  
      .title {
        margin-bottom: 8px; 
      }
  
      .btnWrapper {
        width: 100%; 
      }
    }
  }
}

.StorePromoQ {
  @media (max-width: $screen-width-md) {
    padding: 20px;
  }
}

.searchPromoQ {
  position: relative;
  width: 100%;
  padding: 24px 0;
  background: $white-color;
  margin: 0 16px;

  @media (max-width: $screen-width-md) {
    .itemWrapper {
      padding-left: 12px !important;
      padding-right: 12px !important;
      max-height: none;
    }
  }

  .itemWrapper {
    padding: 13px;
    flex-wrap: wrap; 
    gap: 1rem; 
    justify-content: space-between; 
  }
}

.btnWrapper {
  border-radius: 0;
  width: 160px; 
  height: 48px; 
  padding: 0.8rem 1.5rem;

  @media (max-width: $screen-width-md) {
    font-size: 0.875rem; 
    max-width: max-content;    height: 48px; 
  }
}
