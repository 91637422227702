@import "@styles/variables";

.field {
  height: 48px !important;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 3px 11px;
  color: #02254a;
  font-size: 16px;
  line-height: 1.5;
  list-style: none;
  font-family: Inter, sans-serif;
  position: relative;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #404b6033;
  transition: all 0.2s;
  border-radius: 6px;
  &:disabled{
    color: $neutral-50;
    opacity: 1;
    -webkit-text-fill-color: $neutral-50;
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(64, 75, 96, 0.2);
  }
  &:hover {
    border-color: #2c8de8;
    color: $neutral-50;
    border-inline-end-width: 1px;
  }
  &:focus {
    border-color: #2c8de8;
    box-shadow: 0 0 0 2px rgb(5 165 255 / 10%);
    border-inline-end-width: 1px;
    outline: 0;
  }
  &:disabled:hover {
    border-color: #404b6050;
    border-inline-end-width: 1px;
    cursor: not-allowed;
  }
}
.border-radius-zero {
  border-radius: 0;
}
.invalid-input {
  border-color: #f26767;
}
.font-size-small {
  font-size: 12px;
}
.addressStyles {
  padding-top: 16px;
  padding-bottom: 14px;
  padding-left: 12px;
  padding-right: 12px;
  @media (max-width: $screen-width-md) {
    font-size: 16px;
  }

  &::-webkit-input-placeholder {
    color: $neutral-70;
  }
}
