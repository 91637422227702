@import "@styles/variables";

.myAddresses {
  margin-bottom: 2rem;
  :global {
    .ant-radio-group {
      display: block;
    }
  }
  .trimText{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .myAddressesTitle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 700;
    }
    margin-bottom: 12px;
    :global {
      .ant-btn {
        width: 227px;
        border-radius: 8px;
        height: 48px;
        border: 1px solid rgba(64, 75, 96, 0.2) !important;
        span {
          width: 100%;
          .ant-space {
            justify-content: space-between;
            display: flex;
          }
        }
      }
    }
  }
  .addButton {
    min-width: 227px;
    min-height: 201px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 2px solid $oxford-blue;
    @media (max-width: $screen-width-md){
      &:not(:disabled):hover {
        color: inherit;
        border-color: inherit;
        background-color: inherit;
      }
    }
    .icons {
      background-color: $oxford-blue !important;
      border-radius: 50% !important;
      height: 40px;
      width: 40px;
      margin: auto;
      margin-bottom: 10px;
    }
    span {
      font-size: 22px;
      font-weight: 600;
    }
  }
  .pageBtn {
    margin-bottom: 40px;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
    padding-top: 100px;

    button {
      width: 148px;
      height: 56px;
    }
  }
}
.itemBox {
  min-width: 227px;
  min-height: 201px;
  border-radius: 12px;
  background: rgb(213, 230, 249, 0.2);
  padding: 20px 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .titleContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    h3 {
      font-size: $fs-base;
    }
    :global {
      .ant-radio-wrapper {
        margin-right: 0;
      }
    }
  }
  h3 {
    font-size: 12px;
    font-weight: $bold-font-weight;
    color: $oxford-blue;
    margin-bottom: 0;
    line-height: 18px;
  }
  span {
    font-size: 12px;
  }
  .lastRow {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    :global {
      .ant-tag {
        margin-right: 0 !important;
        height: 21px !important;
        font-size: 10px !important;
        font-weight: 600;
        line-height: 15px !important;
        border-radius: 4px;
        padding: 3px 8px;
      }
    }
    button {
      padding-bottom: 0;
      height: auto;
    }
  }
  .icons {
    display: flex;
    gap: 4px;
    button {
      border: 0;
      background: transparent;
      height: fit-content;
      cursor: pointer;
    }
    div {
      border: 1px solid $neutral-30;
      background: $footer-bg-right;
      border-radius: 50%;
      max-width: unset !important;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.closeButton {
  background-color: transparent;
  width: 14px;
  height: 14px;
}

.makeDefault {
  &:hover {
    color: #02254a;
  }
  h6 {
    border-bottom: 1px solid;
  }
}
