@import "@styles/variables";

.haggleTableWrapper {
  @media (max-width: $screen-width-sm) {
    padding: 0;
    .tableTitle {
      padding: 0 16px;
    }
  }

  .tableTitle {
    max-width: 1172px;
    margin-left: auto;
    margin-right: auto;
    line-height: 44px;
    @media (max-width: $screen-width-md) {
      line-height: normal;
      font-size: 24px;
      margin: 24px auto;
    }
  }

  .haggleTable {
    border-top: 1px solid $primary-color;
    :global {
      .ant-table-content {
        &::-webkit-scrollbar {
          height: 8px;
          border-radius: 50px;
          background-color: $neutral-30;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $primary-color;
          border-radius: 50px;
          height: 35px;
        }
        &::-webkit-scrollbar-thumb:horizontal {
          width: 35px;
        }
      }
      .ant-table-thead {
        tr {
          th,
          td {
            font-size: $fs-h5;
            padding-top: 34px;
            padding-bottom: 14px;
            font-weight: $medium-font-weight;
            color: $primary-color;
            text-transform: uppercase;
            text-align: center !important;
            border-bottom: 1px solid $primary-color;
            &::before {
              content: none !important;
            }
            &:first-child {
              border-bottom: none;
              font-size: 14px;
              font-weight: 400;
              text-align: left !important;
              @media (min-width: $screen-width-md) {
                visibility: hidden;
              }
            }
            @media (max-width: $screen-width-md) {
              font-size: 12px;
              font-weight: 400;
              padding: 20px 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  .tableRow {
    td {
      border-bottom: 1px solid $primary-color !important;
      &:first-child {
        width: 32%;
        min-width: 142px;
        text-align: left;
      }
      text-align: center;
      width: 17%;
      font-size: $fs-h5;
      font-weight: $medium-font-weight;
      border: none;
      &:nth-child(even) {
        background-color: rgba(213, 230, 249, 0.2);
      }
      @media (max-width: $screen-width-md) {
        &:not(:first-child){
          white-space: nowrap;
        }
        div {
          line-height: 16px;
          padding: 0;
          p,
          h2 {
            font-size: 12px;
          }
        }
        font-size: 12px;
        padding: 10px 6px 10px 10px !important;
      }
    }
    &:last-child {
      td {
        border-bottom: none !important;
      }
    }
  }
  .buttonWrapper {
    margin-bottom: 40px;
    @media (max-width: $screen-width-md) {
      margin-top: 6px;
    }
    @media (min-width: $screen-width-md) {
      padding-bottom: 255px;
    }
  }
  @media (max-width: $screen-width-md) {
    .disclaimerMessage{
      padding-top: 16px;
      padding-left: 10px;
      margin-bottom: 20px;
    }
  }
}
