@import "@styles/variables";

.stickyCompareContainer {
  position: fixed;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 10px 14px 64px rgba(0, 0, 0, 0.15);
  border-color: 1px solid $neutral-30;
  justify-content: center;
  .mobileCompareElements {
    display: none;
  }
  .compareElements {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .actionsContainer {
    text-align: right;
    .actionButton {
      height: 48px;
      padding: 0 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .compareProductsButton {
      background-color: $primary-color;
      color: $white-color;
    }
    .compareProductsButton:hover {
      color: $primary-color;
      background-color: $white-color;
    }
  }
  .mobileProductItem {
    display: none;
  }
  .productItem {
    height: 85px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $neutral-30;
    .skeletonImage {
      border-radius: 50%;
      width: 60px;
      height: 60px;
    }
    .productName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    img {
      object-fit: contain;
      width: 60px;
    }
    button {
      background-color: $neutral-30;
      justify-content: center;
      display: flex;
      align-items: center;
      width: 25px;
      height: 25px;
      min-width: 25px;
      border: 0;
    }
    button:hover {
      background-color: $white-color;
    }
    .removeProductBtn {
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .mobileAddProductContainer,
  .panelContent {
    display: none;
  }
  .addIconWrapper {
    border-radius: 50%;
    background-color: $oxford-blue;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .addProductContainer {
    display: flex;
    align-items: center;
    height: 85px;
    justify-content: center;
    border: 1px solid $neutral-30;
    .addProductLink {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button {
      background-color: $oxford-blue;
      justify-content: center;
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;
    }
    .addProductBtn {
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media (max-width: $screen-width-lg) {
  .stickyCompareContainer {
    .actionsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
    }
  }
}

@media (max-width: $screen-width-md) {
  .stickyCompareContainer {
    .compareElements {
      display: none;
    }
    .mobileCompareElements {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mobileCompareAccordion {
        background-color: $white-color;
        border: 0;
        :global {
          .ant-collapse-item {
            .ant-collapse-header {
              .ant-collapse-expand-icon {
                padding-left: 8px;
              }

              padding: 0;
              .ant-row {
                justify-content: space-between;
              }
            }
            .ant-collapse-content {
              .ant-collapse-content-box {
                padding: 0;
              }
            }
          }
        }
        .mobileCompareHeaderWrapper {
          padding: 0;
          .mobileCompareHeader {
            padding: 0;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .mobileProductItem {
        display: flex;
        border: 2px solid $neutral-30;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        img {
          object-fit: contain;
        }
        .skeletonImage {
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }
      }
      .productItem {
        display: none;
      }
      .mobileViewItems {
        height: 72px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $neutral-30;
        .skeletonImage {
          border-radius: 50%;
          width: 40px;
          height: 46px;
        }
        img {
          object-fit: contain;
        }
        button {
          background-color: $neutral-30;
          justify-content: center;
          display: flex;
          align-items: center;
          width: 24px;
          height: 25px;
          min-width: 24px;
          border: 0;
        }
        button:hover {
          background-color: $white-color;
        }
        .removeProductBtn {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .productNameWrapper {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .actionsContainer {
        width: 100%;
        margin-top: 16px;
        .actionContainerWrapper {
          width: 100%;
          button {
            width: 100%;
          }
          .compareBtn {
            margin-right: 16px;
          }
        }
      }
      .addProductContainer {
        display: none;
      }
      .MobileAddProductContainer {
        display: flex;
        align-items: center;
        height: 72px;
        justify-content: center;
        border: 1px solid $neutral-30;
        .addProductLink {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        button {
          background-color: $oxford-blue;
          justify-content: center;
          display: flex;
          align-items: center;
          width: 40px;
          height: 40px;
        }
        .addProductBtn {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .panelContent {
        width: 40px;
        height: 40px;
        border: 2px solid $neutral-30;
        .addIconWrapper {
          padding: 2.5px;
        }
      }
      .mobileAddProductContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          background-color: $oxford-blue;
          justify-content: center;
          display: flex;
          align-items: center;
        }
        .addProductBtn {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
