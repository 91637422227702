@import "@styles/variables";
.drawer {
  max-width: 550px;
  margin-left: auto;
  .checkboxGroup {
    :global {
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(64, 75, 96, 0.2) !important;

        .ant-checkbox-wrapper {
          margin: 0;
        }
      }
    }
  }
  :global {
    .ant-drawer-header {
      padding: 40px 24px 24px;
      border-bottom: 0;

      .ant-drawer-title {
        font-size: 28px;
        font-weight: 700;
      }
    }
    .ant-drawer-body {
      padding: 0 24px 40px;
      .ant-form-item {
        margin-bottom: 0;
      }
    }
    .ant-radio-wrapper {
      span {
        font-weight: 600;
      }
    }
  }
  .closeButton {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: $oxford-blue !important;
    border-radius: 50% !important;
    border: none !important;
    width: 27px;
    height: 27px;
  }
  .deleteBtn {
    display: flex;
    gap: 13px;
    border-bottom: 0;
    padding: 24px 12px;
    font-weight: 600;
  }
  .btnForm {
    display: block;
    width: 100%;
    margin: 50px 0;
    :global {
      button {
        width: 100%;
        height: 56px;
      }
    }
  }
}
