@import "@styles/variables";

.feedBackCard {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ecdfd5;
  height: 100%;
  box-shadow: 7px 7px 10px 0 #E1D3CB;
  .qouteContainer {
    position: absolute;
    bottom: -7px;
    right: 20px;
  }

  .feedBackContent {
    background-color: $white-color;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 36px;

    .readMore:hover {
      color: inherit;
    }

    @media (max-width: $screen-width-sm) {
        padding: 40px 22px 23px;
    }

    .feedBackTime {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 0.5rem;

      svg path {
        fill: $neutral-50;
      }

      span {
        text-transform: uppercase;
        color: $neutral-50;
        font-style: normal;
        font-weight: $bold-font-weight;
        font-size: $fs-xs;
      }
    }

    .titleText {
      font-style: normal;
      font-weight: 700;
      font-size: $fs-h6;
      line-height: 20px;
      color: $oxford-blue;
    }

    .descText {
      font-style: normal;
      color: $black-color;
      font-weight: 400;
      font-size: 14px;
    }

    .userInfoDetails {
      gap: 3px;
    }
    .userName {
      font-style: normal;
      font-weight: $bold-font-weight;
      font-size: $fs-base;
      line-height: $fs-h5;
    }

    .userPosition {
      font-style: normal;
      font-weight: $regular-font-weight;
      font-size: $fs-xs;
      line-height: $fs-base;
    }
  }
}

.userInfoWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  z-index: 1;
  margin-top: auto;
}

.userInfoDetails {
  display: flex;
  flex-direction: column;
}

.userImage {
  border-radius: 50%;
  background-color: #eee;
  width: 54px;
  height: 54px;
  position: relative;
  img {
    padding: 8px !important;
  }
}
.feedBackModal {
  :global {
    .ant-modal-content {
      border-radius: 0;
      padding: 20px 55px 100px 66px;
      @media(max-width: $screen-width-md){
        padding: 20px;
        h4{
          margin-top: 20px;
        }
      }
    }
  }
}
