@import "@styles/variables";

.modal {
  text-align: center;
  color: "red";
}
.shareModal {
  :global {
    .ant-modal-content {
      .ant-modal-header {
        .ant-modal-title {
          font-size: $fs-h3;
          font-weight: $bold-font-weight;
        }
      }
    }
  }
  .shareModalFooter {
    justify-content: center;
    padding-bottom: 14px;
  }
  .socialSharingSection {
    border-top: 1px solid $neutral-30;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: $primary-color;
      border: 0;
    }
    .socialShareButton {
      background-color: $primary-color !important;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .AddAnotherRecipient {
    display: flex;
    align-items: center;
    text-align: center;
  }
}
.requestBttn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.description {
  text-align: start;
}
.successModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.shareModal {
  :global {
    .ant-form-item-explain-error {
      text-align: left;
    }
  }
}
.recipientDetails {
  display: flex;
  text-align: left;
  align-items: center;
  margin-bottom: 8px;

  .removeBtn {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
@media (max-width: $screen-width-md) {
  .shareModal {
    .shareForm {
      padding: 25px 0 0 0;
    }
    .recipientDetails {
      text-align: left;
    }
  }
}
.recaptcha-container {
  z-index: 1001;
}
