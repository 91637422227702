@import "@styles/variables";

.manageAccountStyle {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-input-disabled {
      color: $neutral-50;
      -webkit-text-fill-color: $neutral-50;
      &::placeholder {
        color: $neutral-50;
      }
    }
    .ant-select-selection-search-input{
      height: 48px !important;
    }
    input::placeholder {
      color: $neutral-50;
    }
  }
  &.componentDisabled {
    :global {
      .ant-radio-wrapper-checked {
        display: block !important;
        .ant-radio {
          display: none;
        }
      }
      .ant-radio-wrapper-disabled {
        display: none;
      }
    }
  }
  .checkboxGroup {
    display: flex;
    column-gap: 54px;
    row-gap: 24px;
    :global {
      .ant-checkbox-wrapper {
        margin: 0;
      }
      .ant-checkbox-wrapper-disabled {
        &.ant-checkbox-wrapper-checked {
          display: flex;
        }
      }
    }
  }
  .btnForm {
    float: right;
    margin-bottom: 40px;
    display: flex;
    column-gap: 16px;
    button {
      width: 148px;
      height: 56px;
    }
  }
}
.editBtn {
  width: 148px;
  height: 56px;
  margin-bottom: 40px;
  float: right;
}

.loaderContainer{
  z-index: 10;
  position: absolute;
  top: 70px;
  left: 50%;
}
.showMoreIcon{
  height: 20px;
  color: #333;
}
.icon{
  display: inline-block;
  cursor: pointer;
}