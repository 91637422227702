@import "@styles/variables";

.bannerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: auto;
}

.bannerItem {
  display: flex;
  align-items: center;
  justify-content: center;

  .itemText {
    line-height: 18px;
    text-align: center;
    p {
      margin: 0;
    }
  }

  .shopText {
    a{
      border-bottom: 2px solid $primary-color;
      line-height: $fs-h6;
      &:hover{
        border-bottom: 0;
      }
      &:active{
        color: $white-color;
      }
    }

  }
}

@media (max-width: $screen-width-md) {
  .itemText {
    font-size: $fs-xs;
  }
  .shopText {
    display: none;
  }
  .bannerItem {
    padding: 0 45px;
  }
}
