@import "@styles/variables";

.addUser {
  .btnForm {
    margin-bottom: 40px;
    display: flex;
    column-gap: 16px;
    float: right;
    padding-top: 24px;
    button {
      width: 148px;
      height: 56px;
    }
  }
  .checkboxGroup {
    :global {
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
        background: rgb(213, 230, 249, 0.2);
        padding: 24px;
        gap: 24px;

        .ant-checkbox-wrapper {
          margin: 0;
        }
      }
    }
  }
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
