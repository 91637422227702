@import "@styles/variables";
.accountOverview {
  background: rgb(213, 230, 249, 0.2);
  .titleSection {
    display: flex;
    align-items: center;
    gap: 12px;
    button {
      height: auto;
    }
  }
  .updateButton{
    height: 17px;
    @media (max-width: $screen-width-md) {
      font-size: $fs-xs;
      font-weight: $semi-font-weight;
    }
  }
  .formInput {
    width: auto;
    display: block !important;
    padding: 0 !important;
    border: 0 !important;
    border-bottom: 2px solid rgba(5, 5, 5, 0.06) !important;

    :global {
      .ant-input {
        width: 100% !important;
        background: unset;
        color: $oxford-blue;
      }
      .ant-input-group-addon {
        background: unset;
        font-weight: 500;
        color: $neutral-70;
        @media (max-width: $screen-width-md) {
          color: $neutral-50;
        }
      }
    }
  }
  @media (max-width: $screen-width-md) {
    .titleSection {
      padding: 24px 16px;
      justify-content: space-between;
    }
    .formInput {
      margin: 0 16px;
    }
  }
}
