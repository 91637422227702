@import "@styles/variables";

.dialogShoppingListContainer {
  max-height: 350px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: $neutral-30;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary-light;
    border-radius: 5px;
  }
}
.saveBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.saveWishlistBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.manageBtn {
  width: fit-content;
}
.productCountText:hover {
  color: $black-color;
}

.creatBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.loaderContainer {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.shoppingListNameWrapper {
  align-items: center;
}
.DialogActionsContainer {
  border-top: 1px solid $neutral-30;
}
.shoppingNameInputError {
  border: 1px solid $red-color1;
  input{
    background-color: $white-color;
  }
}
@media (max-width: $screen-width-md) {
  .shoppingListNameWrapper {
    .productName,
    .defaultShoppingList,
    .productsCount {
      margin-bottom: 0;
    }
    .productName {
      span:last-child {
        padding-left: 4px;
      }
    }
  }
}
