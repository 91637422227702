@import "@styles/variables";

.storeLocatorIcon {
  display: flex;
  align-items: center;
  .storeLocatorBtn {
    border: 0;
    background: transparent;
    padding: 14px 0;
    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
}

.addLocation {
  border: 0;
  text-align: left;
  cursor: pointer;
}
.orderDropdown {
  ul {
    li {
      height: 3em;
    }
  }
}
.orderText,
.address {
  color: $neutral-70;
}
.address-text {
  cursor: pointer;
}

.menu {
  display: flex;
  align-items: center;
}
.vendorSwitch {
  text-align: center;
  display: flex;
  align-items: center;
}
.dropdownContent {
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: $white-color;
  color: $black-color;
  &:not(:disabled):hover {
    background-color: $neutral-20;
    border: none;
    color: $black-color;
  }
  .iconWrapper {
    display: flex;
    div {
      display: flex;
      align-items: center;
    }
  }
}
.innerdropdownContent {
  display: flex;
  border-bottom: 1px solid;
  justify-content: space-between;
  flex-direction: row-reverse;
  background-color: $white-color;
  &:not(:disabled):hover {
    background-color: $white-color;
    border-bottom: 1px solid;
  }
}
.dropdownMenu {
  :global {
    .ant-dropdown-menu-item-active {
      background-color: transparent !important;
    }
  }
  ul {
    padding: 26px 18px !important;
  }
}
.locatorContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .itemWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;

    .item {
      text-transform: uppercase;
      font-weight: $bold-font-weight;
    }
  }
  a,
  .locatorContainer {
    padding: 8px 12px;
    line-height: 0;
    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
}

@media (max-width: $screen-width-md) {
  .locatorContainer {
    .storeLocatorIcon {
      margin-left: 0;
    }
    .dropdownContent {
      padding-left: 0;
      .selectedAddress {
        font-size: $fs-sm;
      }
    }
    .menu {
      display: none;
    }
  }
  .mobileLocatorContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0;
    .storeLocatorIcon {
      display: flex;
      align-items: center;
    }
    .menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 40px;
      a {
        padding-top: 28px;
        @media (max-width: $screen-width-md) {
          margin-left: 24px;
        }
      }
    }
    .itemWrapper {
      display: none;
    }
  }
}

.menu {
  :global {
    .ant-menu-item-selected {
      background-color: unset !important;
    }
  }
}
