@import "@styles/variables";
.noResult {
  text-align: center;

  .iconWrapper {
    display: flex;
    justify-content: center;
    height: auto;
  }
  button {
    font-size: $fs-xs;
    font-weight: $bold-font-weight;
  }
}
.closeMenu {
  display: none;
  @media (max-width: $screen-width-md) {
    display: flex;
    justify-content: flex-end;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
}
