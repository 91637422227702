@import "@styles/variables";

.achPayments {
  background: rgb(213, 230, 249, 0.2);
  padding: 24px;
  :global {
    .ant-radio-group {
      width: 100%;
    }
  }
  .makeDefault {
    &:hover {
      color: #02254a;
    }
    h6 {
      border-bottom: 1px solid;
    }
  }
  .loader {
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .achPaymentsLoadingWrapper {
    opacity: 0.4;
    pointer-events: none;
  }
  .itemBox {
    min-width: 227px;
    min-height: 201px;
    border-radius: 12px;
    background: rgb(213, 230, 249, 0.2);
    padding: 20px 16px;
    height: 100%;
    border: 2px solid $oxford-blue;

    .titleContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      :global {
        .ant-radio-wrapper {
          margin-right: 0;
        }
      }
    }
    h3 {
      font-size: 12px;
      font-weight: $bold-font-weight;
      color: $oxford-blue;
      margin-bottom: 0;
      line-height: 18px;
      &.b2 {
        font-size: 16px;
      }
    }
    span {
      font-size: 12px;
    }
    .lastRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      :global {
        .ant-tag {
          margin-right: 0 !important;
          height: 21px !important;
          font-size: 10px !important;
          font-weight: 600;
          line-height: 15px !important;
          border-radius: 4px;
          padding: 3px 8px;
        }
      }
      button {
        padding-bottom: 0;
        height: auto;
      }
    }
    .icons {
      display: flex;
      gap: 4px;
      button {
        border: 0;
        background: transparent;
        height: fit-content;
        cursor: pointer;
      }
      div {
        border: 1px solid $neutral-30;
        background: $footer-bg-right;
        border-radius: 50%;
        max-width: unset !important;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .addButton {
    min-width: 227px;
    min-height: 201px;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 2px solid $oxford-blue;
    @media (max-width: $screen-width-md){
      &:not(:disabled):hover {
        color: inherit;
        border-color: inherit;
        background-color: inherit;
      }
    }
    .icons {
      background-color: $oxford-blue !important;
      border-radius: 50% !important;
      height: 40px;
      width: 40px;
      margin: 5px auto;
    }
    span {
      font-size: 22px;
      font-weight: 600;
    }
  }
  .pageBtn {
    margin-bottom: 40px;
    display: flex;
    column-gap: 16px;
    float: right;
    padding-top: 100px;

    button {
      width: 148px;
      height: 56px;
    }
  }
}
