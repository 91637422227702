@import "@styles/variables";

.checkboxGroup {
  display: flex;
  row-gap: 10px;
  :global {
    .ant-checkbox-wrapper {
      margin: 0;
      
    }
    .ant-checkbox-wrapper-disabled {
      display: none;
      &.ant-checkbox-wrapper-checked {
        display: flex;
      }
    }
  }
}
