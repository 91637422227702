@import "@styles/variables";
.manageUsers {
  :global {
    .ant-tabs-tab {
      padding-top: 0;
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $oxford-blue !important;
      }
    }
    .ant-tabs-ink-bar {
      background: $oxford-blue;
    }
    .ant-tabs-nav {
      &::before {
        border: 0;
      }
    }
  }
}
