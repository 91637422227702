@import "@styles/variables";

.left_container {
  background-image: url("https://22338452.fs1.hubspotusercontent-na1.net/hubfs/22338452/images_storefront/registerForm.png.png");
  background-size: 100% 100%;
  min-height: 100vh;
  .imageContainer {
    padding-left: 80px;
    @media (max-width: $screen-width-lg) {
      padding-left: 40px;
    }
  }
}

.HaggleModal{
    :global{
      .ant-modal-content{
        border-radius: 0;
        margin: 20px 0;
      }
    }
}

.yourSupply {
  margin-top: 64px;
  width: 200px;
  @media (max-width: $screen-width-lg) {
    margin-top: 32px;
    font-size: 50px;
  }
}

.dropMenuStyle {
  min-width: 12.8% !important;
  :global {
    .ant-checkbox-inner {
      border-radius: 0;
    }
  }
  @media (max-width: $screen-width-md) {
    min-width: 25% !important;
  }
  @media (max-width: $screen-width-sm) {
    min-width: 50% !important;
    padding: 4px;
  }
}

.list_style_type {
  list-style-type: none;
  white-space: nowrap;
}
.congratulationsSection {
  align-items: center;
  text-align: center;
  flex-flow: column;
  .congratulations{
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 16px;
    @media (max-width: $screen-width-md) {
      font-size: 24px;
      margin-bottom: 12px;
    }
  }
  .completedProfileMessage, .updateOrChangeInformation, .applyForCreditLine{
    font-size: 16px;
    font-weight: 400;
    @media (max-width: $screen-width-md) {
      font-size: 14px;
    }
  }
  .btn{
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    &:focus{
      border-bottom: 2px solid $primary-color;
    }
  }
  .completedProfileMessage{
    margin-bottom: 8px;
    @media (max-width: $screen-width-md) {
      margin-bottom: 4px;
    }
  }
  .updateOrChangeInformation{
    margin-bottom: 12px;
    @media (max-width: $screen-width-md) {
      margin-bottom: 6px;
    }
  }
  .applyForCreditLine{
    margin-bottom: 32px;
    @media (max-width: $screen-width-md) {
      margin-bottom: 24px;
    }
  }
  .continueShopping{
    border-radius: 0;
    width: 205px;
    height: 49px;
    @media (max-width: $screen-width-md) {
      width: 217px;
    }
  }
}
.border-radius-height {
  border-radius: 0;
  height: 48px;
}
.general-information-form {
  :global {
    .ant-select-selection-item {
      align-self: center;
    }
    .ant-checkbox-inner {
      border-radius: 0;
      width: 20px;
      height: 20px;
    }
    .ant-radio-inner {
      border-color: $black-color !important;
      background-color: $white-color !important;

      &::after {
        background-color: $primary-color;
      }
    }
    .ant-select-selection-search-input{
      height: 48px !important;
    }
  }
}
.dataLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.height-23{
    height: 23px;
}

@media (max-width: $screen-width-md) {
  .general-info-title {
    font-size: $fs-h4;
    color: $black-color;
    margin-bottom: 12px;
  }
  .form-container {
    padding: 0;
    margin: 36px 0;
  }
  .general-info-descritption {
    color: $black-color;
    font-size: $fs-h5;
    margin-bottom: 8px;
  }
}

.other-option{
  margin-top: 10px;
}