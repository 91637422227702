@import "@styles/variables";
.footerContainer {
  @media (max-width: $screen-width-md) {
    padding: 30px 0;
  }

  .upperFooterWrapper {
    justify-content: center;
    align-items: center;
    @media (max-width: $screen-width-md) {
      justify-content: space-between;
    }
    .itemWrapper{
      &:nth-child(2){
        .items{
          p{
            max-width: 200px;
          }
        }
      }
      &:nth-child(3){
        .iconColContainer{
          justify-content: center;
          @media (max-width: $screen-width-sm) {
            justify-content: flex-start;
          }
        }
        .items{
          p{
            max-width: 160px;
          }
        }
      }
      &:nth-child(4){
        .items{
          p{
            max-width: 170px;
          }
        }
      }
    }

  }
}

.iconColContainer {
  display: flex;
  align-items: center;
}
.items
{
  margin-left: 4px;
  font-size: 20px;
  @media (max-width: $screen-width-sm) {
    font-size: $fs-xs;
    max-width: 100px;
  }
  p{
    margin-bottom: 0;
  }
}
