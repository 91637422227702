@import "@styles/variables";
.formWrapper {
  :global {
    .ant-form-item-explain-error {
      padding-left: 12px;
    }
  }
}
.addressDrawer {
  :global {
    .ant-drawer-header {
      padding: 32px 0px 2px 24px;
      @media (max-width: $screen-width-sm) {
        padding: 0 0 16px 0px;
      }
      .ant-drawer-title {
        font-size: 32px;
        font-weight: 700;
        color: #02254a;
        @media (max-width: $screen-width-sm) {
          font-size: 24px;
        }
      }
    }
    .ant-select-selection-placeholder {
      color: $neutral-70;
    }
    @media (max-width: $screen-width-sm) {
      .ant-drawer-body {
        padding: 0;
      }
    }
  }
  .addressHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 10px;
    padding-left: 16px;
    @media (max-width: $screen-width-sm) {
      border-top: 1px solid rgba(137, 137, 137, 0.5);
    }
  }
  .closeBttn {
    margin-top: -3px;
  }
  .addressWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: rgba(#e7e7e7, 0.4);
    padding: 20px 16px;

    .buttonGroup {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .itemBox {
        background-color: $white-color;
        padding: 1rem;
        .radioContentContainer {
          display: flex;
          justify-content: space-between;
          padding-left: 8px;
          .tagContainer {
            position: absolute;
            top: -4px;
            right: 0;
          }
        }
        .titleContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.updateBtn {
  font-weight: 700;
  height: 48px;
}

.formContainer {
  .updateBtn {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .field {
    height: 48px !important;
    display: flex;
    align-items: center;
    border-radius: 0px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    color: $neutral-70;
    padding: 16px 12px 14px 12px !important;
    @media (max-width: $screen-width-md) {
      font-size: 16px;
    }
    :global {
      .ant-select-selector {
        height: 48px !important;
        display: flex;
        align-items: center;
        border-radius: 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.5;
        color: $neutral-70;
        @media (max-width: $screen-width-md) {
          font-size: 16px;
        }
      }
      .ant-select-arrow {
        margin-top: -5px;
      }
      .ant-select-selection-search {
        input {
          @media (max-width: $screen-width-md) {
            font-size: 16px;
          }
        }
      }
      .ant-select-selection-search-input {
        height: 48px !important;
      }
    }
  }
  .customSelect {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .pac-container-parent {
    position: relative;
    .pac-container {
      top: 0 !important;
      left: 0 !important;
    }
  }
  .showMoreIcon {
    height: 20px;
    color: #333;
  }
  .checkboxGroup {
    display: flex;
    column-gap: 54px;
    row-gap: 20px;
    .icon {
      display: inline-block;
      cursor: pointer;
    }
    :global {
      .ant-checkbox-wrapper {
        margin: 0;
      }
      .ant-checkbox-wrapper-disabled {
        &.ant-checkbox-wrapper-checked {
          display: flex;
        }
      }
    }
  }
}
