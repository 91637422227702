@import "@styles/variables";

.quantityInput {
  width: 105px;

  border: 1px solid $neutral-30;
  :global {
    .ant-input-number {
      border: 0;
      background-color: $transparent;
      height: 100%;
      &:focus {
        box-shadow: unset;
        border-color: transparent;
      }
    }
    .ant-input-number-wrapper {
      height: 48px;
      .ant-input-number-group-addon {
        background-color: $transparent;
        padding-left: 4px;
        padding-right: 4px;
        border-radius: 0;
        border: 0;
        button:not(:disabled):focus-visible {
          outline: 0;
        }
        button:disabled {
          opacity: 0.5;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $transparent;
          width: 20px;
          height: 20px;
          border: 0;
          box-shadow: unset;
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .ant-input-number-input-wrap {
      height: 100%;
      background-color: transparent;
      border: 0;

      input {
        height: 100%;
        padding: 0;
        text-align: center;
        font-weight: $bold-font-weight;
        font-size: $fs-h6;
        border: 0;
      }
    }
  }
}
.quantityInput:hover {
  border-color: $neutral-30;
}
