@import "@styles/variables";

.tabMenuContainer {
  position: absolute;
  z-index: 999;
  background: $white-color;
  top: 96px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px;

  :global {
    .ant-tabs-nav-wrap {
      background-color: $primary-lighter !important;
      .ant-tabs-tab {
        padding: 0 !important;
        padding: 24px !important;
        margin: 0 24px !important;
        font-size: 18px !important;
        color: $oxford-blue !important;
        font-weight: bold;
        &.ant-tabs-tab-active {
          div {
            color: $white-color !important;
          }
          background-color: $oxford-blue;
        }
        &.ant-tabs-tab-disabled {
          font-size: 32px !important;
          font-weight: bold;
          color: $black-color !important;
          cursor: context-menu !important;
          padding-left: 0 !important;
          div:active {
            color: unset !important;
          }
        }
      }
      .ant-tabs-ink-bar {
        display: none !important;
      }
    }
  }
  @media (max-width: $screen-width-md) {
    display: none;
  }
  @media (min-width: $screen-width-sm) {
    max-width: calc(100% - 80px);
  }
  @media (min-width: $screen-width-lg) {
    max-width: calc(100% - 160px);
  }
}

.tabContent {
  display: flex;
  height: 100%;
  justify-content: space-between;
}
.ulData {
  display: flex;
  gap: 24px;
  padding-top: 78px;
  padding-right: 24px;

  ul {
    list-style: unset;
    padding-left: 0;
    li {
      padding-bottom: 36px;
      a {
        font-size: 14px;
        line-height: 17px;
        color: $black-color;
        &:hover {
          border-bottom: 4px solid $primary-color;
        }
      }
    }
    button {
      line-height: 15px;
      border-radius: 0;
      width: 71px;
    }
  }
}
.banner {
  padding: 73px 62px 73px 60px;
  border-left: 1px solid $neutral-30;
  @media (min-width: $screen-width-sm) {
    padding: 73px 20px 60px;
  }
  .dateSection {
    display: flex;
    padding: 23px 0 4px;
    gap: 4px;
    div {
      display: inline-flex !important;
    }
    .date {
      font-size: 10px;
      font-weight: bold;
      line-height: 15px;
      color: $oxford-blue;
    }
  }

  .title {
    font-size: 32px;
    color: $oxford-blue;
    line-height: 39px;
    font-weight: bold;
    padding-bottom: 63px;
    width: 328px;
    @media (max-width: $screen-width-lg) {
      font-size: 24px;
      width: 230px;
      padding-bottom: 50px;
    }
  }
}
.readMore {
  width: 138px;
  height: 48px;
  border-radius: 0;
  border: 1px solid $oxford-blue;
  display: block;
  text-align: center;
  span {
    display: block;
  }
}
