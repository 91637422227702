@import "@styles/variables";
.ManageUsersTab {
  :global {
    .ant-table-wrapper {
      margin: 0;
      width: 100%;
      .ant-table-thead {
        .ant-table-cell {
          background: $white-color;
          padding: 24px 0 16px 0;
          font-weight: 700;
          color: $oxford-blue;
          @media (max-width: $screen-width-xs) {
            padding: 24px 8px 16px 8px;
          }
          &::before {
            content: unset !important;
          }
        }
        .permissions {
          padding-left: 12%;
          @media (max-width: $screen-width-xs) {
            padding-left: 0;
          }
        }
      }
      .ant-table-tbody {
        color: $oxford-blue;

        .ant-table-cell {
          padding: 16px 0;
          @media (max-width: $screen-width-xs) {
            padding: 16px 8px;
          }
        }
        .permissions {
          text-align: center;
          .ant-row {
            max-width: 304px;
            margin: 0 auto;
            column-gap: 4px;
          }
        }
      }
    }
    .ant-tag {
      padding: 3px 6px;
      height: auto !important;
      border-radius: 4px;
      margin: 0;
      span {
        text-transform: capitalize;
        font-weight: 600;
      }
    }
  }
  .username {
    display: flex;
    flex-direction: column;

    .name {
      font-size: 16px;
      font-weight: 600;
    }
    .email {
      font-weight: 500;
      font-size: 12px;
    }
  }
  .icons {
    display: flex;
    gap: 12px;
    max-width: 47px;
    @media (max-width: $screen-width-xs) {
      max-width: unset;
    }
    button {
      border: 0;
      background: transparent;
      height: fit-content;
      cursor: pointer;
    }
    div {
      border: 1px solid $neutral-30;
      background: $footer-bg-right;
      border-radius: 50%;
      max-width: unset !important;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .pageBtn {
    margin-bottom: 40px;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
    padding-top: 100px;

    button {
      width: 148px;
      height: 56px;
    }
  }
}
