@import "@styles/variables";
.updatePassword {
  .btnForm {
    margin-bottom: 40px;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
    button {
      width: 148px;
      height: 56px;
    }

    @media (max-width: $screen-width-xs) {
      width: 100%;
      gap: unset;
      justify-content: space-between;
    }
  }
  :global {
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
}
