@import "@styles/variables";

.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 3px 11px;
  color: $oxford-blue;
  width: 100%;
  border-radius: 6px;
  transition: all 0.2s;
  border: 1px solid $input-border;
  background-color: $white-color;
  &:hover {
    border-color: $secondary-blue;
    border-inline-end-width: 1px;
  }
  &:focus {
    border-color: $secondary-blue;
    box-shadow: 0 0 0 2px rgb(5 165 255 / 10%);
    border-inline-end-width: 1px;
    outline: 0;
  }
  &::placeholder {
    color: $neutral-70;
  }
  @media (max-width: $screen-width-md) {
    font-size: 16px;
  }
}
.ant-input-affix-wrapper {
  width: 100%;
  padding: 3px 11px;
  color: $oxford-blue;
  border: 1px solid $input-border;
  border-radius: 6px;
  transition: all 0.2s;
  &:hover {
    border-color: $secondary-blue;
    border-inline-end-width: 1px;
  }
  &:focus {
    border-color: $secondary-blue;
    box-shadow: 0 0 0 2px rgb(5 165 255 / 10%);
    border-inline-end-width: 1px;
    outline: 0;
  }
  @media (max-width: $screen-width-md) {
    font-size: 16px;
  }
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  opacity: 1;
}
.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper > textarea.ant-input {
  border: none;
  outline: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
  resize: vertical;
}
.ant-form-item-explain-error {
  font-size: $fs-xs;
  color: $tertiary-pink;
}
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  border-color: $tertiary-pink;
}

.ant-select-selector {
  input::placeholder {
      color: $neutral-70;
    }
  .ant-select-selection-placeholder {
    color: $neutral-70;
  }
}